import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function GeneralSettings() {
  return (
    <Box component="form" noValidate autoComplete="off">
      <TextField
        fullWidth
        label="Store Name"
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Store Address"
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Contact Number"
        variant="outlined"
        margin="normal"
      />
      <Button variant="contained" color="primary" sx={{ mt: 2 }}>
        Save Changes
      </Button>
    </Box>
  );
}

export default GeneralSettings;
