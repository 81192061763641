import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography, // Import Typography here
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase'; // Import your Firebase auth
import logo from '../assets/logo1-removebg-preview.png'; // Import the logo image

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(1.5),
}));

const Navbar = () => {
  const [openBalanceDialog, setOpenBalanceDialog] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function

  // Mock data for till balance and cash
  const tillBalance = 1200.50;
  const cashAmount = 350.00;

  const handleOpenBalanceDialog = () => {
    setOpenBalanceDialog(true);
  };

  const handleCloseBalanceDialog = () => {
    setOpenBalanceDialog(false);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Redirect to login page after successful logout
        navigate('/');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: 'green', zIndex: 1300, height: '64px' }}>
        <Toolbar style={{ minHeight: '64px' }}>
          <img 
            src={logo} 
            alt="Oseo POS Logo" 
            style={{ height: '64px', width: 'auto', marginRight: '16px' }} 
          />
          <Box sx={{ flexGrow: 1 }} />
         
          <Button color="inherit" onClick={handleLogout} style={{ fontSize: '0.75rem' }}>
            Log Out
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog open={openBalanceDialog} onClose={handleCloseBalanceDialog}>
        <DialogTitle>View Till Balance and Cash</DialogTitle>
        <DialogContent>
          <StyledBox>
            <Typography variant="h6">Till Number Balance</Typography>
            <Typography variant="body1">${tillBalance.toFixed(2)}</Typography>
          </StyledBox>
          <StyledBox>
            <Typography variant="h6">Cash Amount</Typography>
            <Typography variant="body1">${cashAmount.toFixed(2)}</Typography>
          </StyledBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBalanceDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
