import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { collection, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, TextField, Typography, IconButton, InputAdornment, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Edit, Delete, AddCircleOutline, RemoveCircleOutline, ArrowBack } from '@mui/icons-material';
import { toast } from "react-toastify";

const getMuiTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: '#4CAF50',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#ffffff',
          '&:hover': {
            backgroundColor: '#388E3C',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          fontSize: '0.725rem',
        },
        head: {
          fontSize: '0.78rem',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: darkMode ? '#333333' : 'rgba(0, 0, 0, 0.04)',
          },
          '&:nth-of-type(even)': {
            backgroundColor: darkMode ? '#444444' : '#ffffff',
          },
        },
      },
    },
  },
});

const EditProductPage = () => {
  const [shopsname,setShop] = useState("") ;
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    category: "",
    productName: "",
    totalItems: 0,
    buyingPrice: "",
    sellingPrice: "",
    serialNumbers: [],
  });
  const [editMode, setEditMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [errors, setErrors] = useState({});
  const [shopNames, setShopNames] = useState([]);

  useEffect(() => {
    const fetchShopNames = async () => {
      const shoplistCollection = collection(db, 'shoplist');
      const shoplistSnapshot = await getDocs(shoplistCollection);
      const shopList = shoplistSnapshot.docs.map(doc => doc.data().shopName);
      setShopNames(shopList);
    };

    const fetchProducts = async () => {
      let productList = [];

      for (const shopName of shopNames) {
        const productsCollection = collection(db, `received_products/${shopName}/products`);
        const snapshot = await getDocs(productsCollection);
        const shopProducts = snapshot.docs.map((doc, index) => ({
          id: doc.id,
          ...doc.data(),
          shopName, // Attach shopName to each product for easy access later
          index: index + 1
        }));
        productList = [...productList, ...shopProducts];
      }

      setProducts(productList);
    };

    fetchShopNames().then(fetchProducts);
  }, [shopNames]);

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    setFormData({
      category: product.category,
      name: product.productName,
      total: product.totalItems,
      buyingPrice: product.buyingPrice,
      sellingPrice: product.sellingPrice,
      serialNumbers: product.serialNumbers || [],
    });
    setEditMode(true);
  };

  const handleDeleteClick = (product,shopname) => {
    setCurrentProduct(product);
    setShop(shopname);

    setOpenDeleteDialog(true);
    
  };

  const handleBackClick = () => {
    setEditMode(false);
    setSelectedProduct(null);
  };

  const handleFormChange = (e) => {
    const { name, value, type } = e.target;
  
    // If the field is a number, ensure we store it as a number instead of a string
    const formattedValue = type === 'number' ? parseFloat(value) : value;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };
  
  const handleSerialNumberChange = (index, value) => {
    const updatedSerialNumbers = [...formData.serialNumbers];
    updatedSerialNumbers[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      serialNumbers: updatedSerialNumbers,
    }));
  };

  const handleAddSerialNumber = () => {
    setFormData((prevData) => ({
      ...prevData,
      serialNumbers: [...prevData.serialNumbers, ''],
    }));
  };

  const handleRemoveSerialNumber = (index) => {
    const updatedSerialNumbers = formData.serialNumbers.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      serialNumbers: updatedSerialNumbers,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedProduct) return;

    const productRef = doc(db, `received_products/${selectedProduct.shopName}/products`, selectedProduct.id);
    await updateDoc(productRef, {
      category: formData.category,
      productName: formData.name,
      quantity: formData.serialNumbers.length,
      totalItems: formData.total,
      buyingPrice: formData.buyingPrice,
      sellingPrice: formData.sellingPrice,
      serialNumbers: formData.serialNumbers,
    });

    // Reset the form and mode
    setEditMode(false);
    setSelectedProduct(null);
    setFormData({
      category: '',
      name: '',
      total: 0,
      buyingPrice: '',
      sellingPrice: '',
      serialNumbers: [],
    });
  };

  const handleDeleteSubmit = async () => {
    if (!currentProduct) return;

    const productRef = doc(db, `received_products/${shopsname}/products`, currentProduct);
    await deleteDoc(productRef);

    // Close the dialog and reset state
    setOpenDeleteDialog(false);
    setCurrentProduct(null);
    setEditMode(false);
    setSelectedProduct(null);
    setFormData({
      category: '',
      name: '',
      total: 0,
      buyingPrice: '',
      sellingPrice: '',
      serialNumbers: [],
    });
  };


  const columns = [
    { name: "index", options: { filter: true, sort: false }, label: "#" },
    { name: "shopName", options: { filter: true, sort: false }, label: "Shop" },
    { name: "category", options: { filter: true, sort: false }, label: "Category" },
    { name: "productName", options: { filter: true, sort: false }, label: "Name" },
    { name: "totalItems", options: { filter: true, sort: false }, label: "Total Items" },
    { name: "quantity", options: { filter: true, sort: false }, label: "In Stock" },
    { name: "buyingPrice", options: { filter: true, sort: false }, label: "Buying Price" },
    { name: "sellingPrice", options: { filter: true, sort: false }, label: "Selling Price" },
  
    {
      name: "id",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const shopame = tableMeta.rowData[1]; // Get shopName from the row (column index 1)
          return (
            <div>
              <IconButton onClick={() => handleEditClick(products[tableMeta.rowIndex])}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => { handleDeleteClick(value, shopame); toast.info(value);}}>
                <Delete />
              </IconButton>
            </div>
          );
        },
      },
      label: "Actions",
    },
  ];
  

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 50, 100],
    downloadOptions: {
      filename: 'ProductData.csv',
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
  };

  return (
    <ThemeProvider theme={getMuiTheme(false)}>
      {editMode ? (
        <div>
          <Button startIcon={<ArrowBack />} onClick={handleBackClick} sx={{ mb: 3 }}>
            Back to Products
          </Button>
          <form onSubmit={handleSubmit}>
  <Box margin={2}>
    <TextField
      label="Category"
      variant="outlined"
      name="category"
      value={formData.category}
      onChange={handleFormChange}
      fullWidth
      required
      margin="normal"
      error={Boolean(errors.category)}
      helperText={errors.category}
    />
    <TextField
      label="Name"
      variant="outlined"
      name="name"
      value={formData.name}
      onChange={handleFormChange}
      fullWidth
      required
      margin="normal"
      error={Boolean(errors.name)}
      helperText={errors.name}
    />
    <TextField
      label="Total Items"
      variant="outlined"
      name="total"
      value={formData.total}
      onChange={handleFormChange}
      fullWidth
      required
      type="number"
      margin="normal"
      error={Boolean(errors.total)}
      helperText={errors.total}
    />
    <TextField
      label="Buying Price"
      variant="outlined"
      name="buyingPrice"
      value={formData.buyingPrice}
      onChange={handleFormChange}
      fullWidth
      required
      margin="normal"
      type="number"
      inputProps={{ step: '0.01' }}
      InputProps={{
        startAdornment: <InputAdornment position="start">ksh</InputAdornment>,
      }}
      error={Boolean(errors.buyingPrice)}
      helperText={errors.buyingPrice}
    />
    <TextField
      label="Selling Price"
      variant="outlined"
      name="sellingPrice"
      value={formData.sellingPrice}
      onChange={handleFormChange}
      fullWidth
      required
      margin="normal"
      type="number"
      inputProps={{ step: '0.01' }}
      InputProps={{
        startAdornment: <InputAdornment position="start">ksh</InputAdornment>,
      }}
      error={Boolean(errors.sellingPrice)}
      helperText={errors.sellingPrice}
    />
    <Typography variant="h6" gutterBottom>
      Serial Numbers
    </Typography>
    {formData.serialNumbers.map((serialNumber, index) => (
      <Box key={index} display="flex" alignItems="center" mb={1}>
        <TextField
          label={`Serial Number ${index + 1}`}
          variant="outlined"
          value={serialNumber}
          onChange={(e) => handleSerialNumberChange(index, e.target.value)}
          fullWidth
          margin="normal"
        />
        <IconButton onClick={() => handleRemoveSerialNumber(index)} color="error">
          <RemoveCircleOutline />
        </IconButton>
      </Box>
    ))}
    <Button
      variant="outlined"
      color="primary"
      margin="20px"
      startIcon={<AddCircleOutline />}
      onClick={handleAddSerialNumber}
      fullWidth
    >
      Add Serial Number
    </Button>
    <Button
      variant="outlined"
      color="secondary"
      type="submit"
      fullWidth
    >
      Save Changes
    </Button>
  </Box>
</form>

         
        </div>
      ) : (
      <div>
        <MUIDataTable
          title={"Products"}
          data={products}
          columns={columns}
          options={options}
        />
         <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this product?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteSubmit} color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          </div>
      )}
    </ThemeProvider>
  );
};

export default EditProductPage;
