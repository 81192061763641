import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse,
  Toolbar,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Add,
  Category,
  LocalMall,
  Receipt,
  Storefront,
  Business,
  ManageAccounts,
  Settings,
  ShoppingCart,
  Report,
  Dashboard as DashboardIcon,
  ViewList,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

function Sidebar({ role, username }) {
  const [openProducts, setOpenProducts] = useState(false);
  const [openSalesPeople, setOpenSalesPeople] = useState(false);
  const [openOperations, setOpenOperations] = useState(false);
  const [openStore, setOpenStore] = useState(false);

  const closeAll = () => {
    setOpenProducts(false);
    setOpenSalesPeople(false);
    setOpenOperations(false);
    setOpenStore(false);
  };

  const toggleProducts = () => {
    closeAll();
    setOpenProducts(!openProducts);
  };

  const toggleSalesPeople = () => {
    closeAll();
    setOpenSalesPeople(!openSalesPeople);
  };

  const toggleOperations = () => {
    closeAll();
    setOpenOperations(!openOperations);
  };

  const toggleStore = () => {
    closeAll();
    setOpenStore(!openStore);
  };

  const isAdmin = role === 'admin';

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <div
        style={{
          padding: '16px',
          textAlign: 'center',
          backgroundColor: '#f7f7f7',
          minHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            backgroundColor: '#388E3C',
            color: 'white',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
          }}
        >
          {username.charAt(0).toUpperCase()}
        </div>
        <h3 style={{ color: '#424242' }}>{username}</h3>
        <Divider />
        <List>
          <ListItem button component={Link} to={isAdmin ? "/dashboard" : "/userdashboard"}>
            <ListItemIcon>
              <DashboardIcon style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" style={{ color: '#424242' }} />
          </ListItem>

          <ListItem button component={Link} to="/sales">
            <ListItemIcon>
              <ShoppingCart style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Sales" style={{ color: '#424242' }} />
          </ListItem>

          <ListItem button onClick={toggleOperations}>
            <ListItemIcon>
              <Business style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Operations" style={{ color: '#424242' }} />
            {openOperations ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openOperations} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/add-category" disabled={!isAdmin}>
                <ListItemIcon>
                  <Category style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Create Category" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/create-product" disabled={!isAdmin}>
                <ListItemIcon>
                  <LocalMall style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Add Product" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/receive-product" disabled={!isAdmin}>
                <ListItemIcon>
                  <Receipt style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Receive Products" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/edit-product" disabled={!isAdmin}>
                <ListItemIcon>
                  <Settings style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Edit Products" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/view-product">
                <ListItemIcon>
                  <ViewList style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="View Products" style={{ color: '#424242' }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={toggleStore} >
            <ListItemIcon>
              <Storefront style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="SHOP" style={{ color: '#424242' }} />
            {openStore ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openStore} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/add-shop" disabled={!isAdmin}>
                <ListItemIcon>
                  <Add style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Add SHOP" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/manage-shop" >
                <ListItemIcon>
                  <Settings style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Manage SHOP" style={{ color: '#424242' }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={toggleSalesPeople} disabled={!isAdmin}>
            <ListItemIcon>
              <ManageAccounts style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Sales People" style={{ color: '#424242' }} />
            {openSalesPeople ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSalesPeople} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/add-salesperson" disabled={!isAdmin}>
                <ListItemIcon>
                  <Add style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Add Salesperson" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/manage-salespeople" disabled={!isAdmin}>
                <ListItemIcon>
                  <ManageAccounts style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Manage Salespeople" style={{ color: '#424242' }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button component={Link} to="/reports">
            <ListItemIcon>
              <Report style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Reports" style={{ color: '#424242' }} />
          </ListItem>

          <ListItem button component={Link} to="/system-settings" disabled={!isAdmin}>
            <ListItemIcon>
              <Settings style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="System Settings" style={{ color: '#424242' }} />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

export default Sidebar;
