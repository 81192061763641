import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, auth } from "../firebase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Tooltip,
  CssBaseline,
  Switch,
  FormControlLabel,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShowIcon from "@mui/icons-material/Visibility";
import TaskAlt from "@mui/icons-material/TaskAltOutlined";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";

const getMuiTheme = (darkMode) =>
  createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#4CAF50",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
            backgroundColor: "#ffffff",
            "&:hover": {
              backgroundColor: "#388E3C",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px 8px",
            fontSize: "0.725rem",
          },
          head: {
            fontSize: "0.78rem",
            backgroundColor: "#FFFFFF",
            color: "#000000",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:nth-of-type(odd)": {
              backgroundColor: darkMode ? "#333333" : "#f5f5f5",
            },
            "&:nth-of-type(even)": {
              backgroundColor: darkMode ? "#444444" : "#ffffff",
            },
          },
        },
      },
    },
  });

const ViewProducts = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState(["All"]);
  const [shops, setShops] = useState(["All"]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedShop, setSelectedShop] = useState("All");
  const [darkMode, setDarkMode] = useState(false);
  const [isSelectable, setIsSelectable] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [remainingProducts, setRemainingProducts] = useState(0);
  const [role, setRole] = useState("");

  const fetchUserRoleAndShop = async () => {
    const userEmail = auth.currentUser.email;
    const employeeSnapshot = await getDocs(
      query(collection(db, "employees"), where("email", "==", userEmail))
    );
    const employeeData = employeeSnapshot.docs[0].data();
    const { role, assignedShop } = employeeData;

    const shoplistSnapshot = await getDocs(collection(db, "shoplist"));
    const shopNames = shoplistSnapshot.docs.map((doc) => doc.data().shopName);
    setShops(["All", ...shopNames]);

    fetchData(role, assignedShop);
    setRole(role);
  };
  useEffect(() => {
    
  });
  const fetchData = async (role, assignedShop) => {
    let productsData = [];

    if (role === "shopmanager") {
      const productsQuery = query(
        collection(db, `received_products/${assignedShop}/products`)
      );
      const querySnapshot = await getDocs(productsQuery);
      productsData = querySnapshot.docs.map((doc, index) => ({
        id: index + 1,
        ...doc.data(),
      }));
    } else if (role === "admin") {
      const shopNamesToFetch =
        selectedShop === "All" ? shops.slice(1) : [selectedShop];

      for (const shopName of shopNamesToFetch) {
        const productsQuery = query(
          collection(db, `received_products/${shopName}/products`)
        );
        const querySnapshot = await getDocs(productsQuery);
        const shopProducts = querySnapshot.docs.map((doc, index) => ({
          id: productsData.length + index + 1,
          ...doc.data(),
          shopName,
        }));
        productsData = [...productsData, ...shopProducts];
      }
    }

    setData(productsData);
    filterData(productsData, selectedCategory, selectedShop);
  };

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const categoryData = querySnapshot.docs.map((doc) => doc.data().name);
    setCategories(["All", ...categoryData]);
  };

  const filterData = (products, category, shop) => {
    const filtered = products.filter((product) => {
      const categoryMatch = category === "All" || product.category === category;
      const shopMatch = shop === "All" || product.shopName === shop;
      return categoryMatch && shopMatch;
    });

    calculateTotals(filtered);
    setFilteredData(filtered);
  };

  const calculateTotals = (products) => {
    const totalItems = products.reduce(
      (sum, item) => sum + (item.totalItems || 0),
      0
    );
    const totalQuantity = products.reduce(
      (sum, item) => sum + (item.quantity || 0),
      0
    );
    setTotalProducts(totalItems);
    setRemainingProducts(totalQuantity);
  };

  useEffect(() => {
    fetchUserRoleAndShop();
    fetchCategories();
  }, []);

  useEffect(() => {
    filterData(data, selectedCategory, selectedShop);
  }, [selectedCategory, selectedShop]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleShopChange = (event) => {
    setSelectedShop(event.target.value);
  };

  const handleRowExpand = (rowIndex) => {
    setIsExpandable(rowIndex === isExpandable ? null : rowIndex);
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case "approved":
        return (
          <Tooltip title="Approved">
            <TaskAlt style={{ color: "green", fontSize: 14 }} />
          </Tooltip>
        );
      case "pending":
        return (
          <Tooltip title="Pending">
            <HistoryIcon style={{ color: "grey", fontSize: 14 }} />
          </Tooltip>
        );
      case "rejected":
        return (
          <Tooltip title="Rejected">
            <CloseIcon style={{ color: "red", fontSize: 14 }} />
          </Tooltip>
        );
      default:
        return null;
    }
  };
  
  // Table columns definition
  const columns = [
    { name: "id", label: "#", options: { filter: false, sort: false } },
    { name: "shopName", label: "Shop", options: { filter: false, sort: false } },
    {
      name: "productName",
      label: "Product Name",
      options: { filter: true, sort: false },
    },
    {
      name: "category",
      label: "Category",
      options: { filter: true, sort: false },
    },
    {
      name: "buyingPrice",
      label: "Buying Price",
      options: { filter: true, sort: false },
    },
    {
      name: "sellingPrice",
      label: "Selling Price",
      options: { filter: true, sort: false },
    },
    {
      name: "totalItems",
      label: "Total Items",
      options: { filter: true, sort: false },
    },
    {
      name: "quantity",
      label: "Remaining Items",
      options: { filter: true, sort: false },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => renderStatusIcon(value),
      },
    },
    {
      name: "serialNumbers",
      label: "Serial Numbers",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          return (
            <IconButton onClick={() => handleRowExpand(rowIndex)}>
              <ShowIcon />
            </IconButton>
          );
        },
      },
    },
    // Conditionally add a column for reject reason if the status is rejected
    {
      name: "rejectReason",
      label: "Reject Reason",
      options: {
        filter: false,
        sort: false,
        display: false, // Hide by default, will show conditionally
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[8]; // Assuming the status column index is 8
          return status === "rejected" ? (
            <Typography variant="body2">{value}</Typography>
          ) : null;
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 20],
    selectableRows: isSelectable ? "multiple" : "none",
    expandableRows: isExpandable !== null,
    renderExpandableRow:
      isExpandable !== null
        ? (rowData, rowMeta) => {
            const rowIndex = rowMeta.dataIndex;
            const serialNumbers = data[rowData[0] - 1].serialNumbers || [];
            return (
              <tr>
                <td colSpan={columns.length}>
                  <Box display="flex" margin={2}>
                    <Typography variant="body2">
                      Serial Numbers:{" "}
                      {serialNumbers.map((sn, i) => (
                        <div key={i}>
                          {i + 1}. {sn}
                        </div>
                      ))}
                    </Typography>
                    <Typography marginLeft="50px" variant="body2">
                     Reason:
                      {data[rowIndex+1] === 'rejected' ? data[rowIndex+2] : "complete"}
                    </Typography>
                  </Box>
                </td>
              </tr>
            );
          }
        : undefined,
    onTableChange: (action, tableState) => {
      if (action === "rowExpansionChange") {
        const expandedRows = tableState.expandedRows.data.map(
          (row) => row.index
        );
        setIsExpandable(expandedRows.length ? expandedRows[0] : null);
      }
    },
    onPrint: (tableData) => {
      const printContent = `
        <div>
          <h2>COWTRACK LIMITED</h2>
          <h4>Category: ${selectedCategory}</h4>
          <table border="1" cellspacing="0" cellpadding="8">
            <thead>
              <tr>
                ${columns.map(
                  (col) =>
                    col.name !== "serialNumbers" &&
                    `<th>${col.label}</th>`
                ).join("")}
              </tr>
            </thead>
            <tbody>
              ${tableData
                .map(
                  (row) => `
                <tr>
                  ${row.data
                    .map(
                      (data, index) =>
                        columns[index].name !== "serialNumbers" &&
                        `<td>${data}</td>`
                    )
                    .join("")}
                </tr>`
                )
                .join("")}
            </tbody>
          </table>
          <h4>Total Products: ${totalProducts}</h4>
          <h4>Total Remaining Items: ${remainingProducts}</h4>
        </div>
      `;
      const printWindow = window.open("", "_blank");
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme(darkMode)}>
      <CssBaseline />
      <Box>
      <FormControlLabel
          control={
            <Switch
              checked={darkMode}
              onChange={(e) => setDarkMode(e.target.checked)}
            />
          }
          label="Dark Mode"
        />
      </Box>
      <Box display="flex" flexDirection="column" padding={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom={2}
          alignItems="center"
        >
          <FormControl variant="outlined" sx={{ width: 200 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Category"
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box mx={2}></Box>
          <FormControl variant="outlined" sx={{ width: 200 }}>
            <InputLabel>Shop</InputLabel>
            <Select value={selectedShop} onChange={handleShopChange} label="Shop">
              {shops.map((shop) => (
                <MenuItem key={shop} value={shop}>
                  {shop}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton onClick={() => fetchUserRoleAndShop()}>
            <RefreshIcon />
          </IconButton>
        </Box>
        <MUIDataTable
          title={"View Products"}
          data={filteredData}
          columns={columns}
          options={options}
        />
      </Box>
      <Box padding={2} display="flex" justifyContent="space-between">
        <Typography variant="body2">
          Total Products: {totalProducts}
        </Typography>
        <Typography variant="body2">
          Total Remaining Items: {remainingProducts}
        </Typography>
        
      </Box>
    </ThemeProvider>
  );
};

export default ViewProducts;
