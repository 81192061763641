import React, { useState, useEffect,useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from '@mui/material';
import { DeleteOutline, Print, Edit } from '@mui/icons-material';
import { collection, addDoc, getDocs, query, where, Timestamp, doc, updateDoc, increment, arrayUnion } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase'; // Adjust the path according to your file structure
import { toast } from 'react-toastify';
import logo from '../assets/logo2.jpg';

function ReceiveProductPage() {
  const [issubmit , setsubmit] = useState(true);
  const [category, setCategory] = useState('');
  const [productName, setProductName] = useState('');
  const [buyingPrice, setBuyingPrice] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [currentSerial, setCurrentSerial] = useState('');
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [receivedBy, setReceivedBy] = useState('');
  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState('');
  const [receivedProducts, setReceivedProducts] = useState([]);
  const [duplicateSerial, setDuplicateSerial] = useState(false);
  const [error, setError] = useState('');
  const[productss,setproductss]=useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // Track index of the item being edited

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    const fetchCategories = async () => {
      const categoryCollection = collection(db, 'categories');
      const categorySnapshot = await getDocs(categoryCollection);
      const categoryList = categorySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setCategories(categoryList);
    };

    const fetchProducts = async () => {
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      const productList = productSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
    };

    const fetchEmployeeName = async () => {
      if (user?.email) {
        const employeesCollection = collection(db, 'employees');
        const q = query(employeesCollection, where('email', '==', user.email));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const employee = querySnapshot.docs[0].data();
          setReceivedBy(employee.name);
        }
      }
    };

    const fetchShops = async () => {
      const shopsCollection = collection(db, 'shoplist');
      const shopsSnapshot = await getDocs(shopsCollection);
      const shopsList = shopsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setShops(shopsList);
    };

    fetchCategories();
    fetchProducts();
    fetchEmployeeName();
    fetchShops();
  }, []);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    const filtered = products.filter((product) => product.category?.toLowerCase() === selectedCategory.toLowerCase());
    setFilteredProducts(filtered);
  };

  const handleProductNameChange = (event) => {
    const selectedProduct = event.target.value;
    setProductName(selectedProduct);

    const product = filteredProducts.find((prod) => prod.name === selectedProduct);
    if (product) {
      setBuyingPrice(product.buyingPrice);
      setSellingPrice(product.sellingPrice);
    }
  };

  const handleSerialNumberChange = (value) => {
    if (value.trim() !== '' && !serialNumbers.includes(value.trim())) {
      setSerialNumbers((prev) => {
        const newSerialNumbers = [...prev];
        if (newSerialNumbers.length < quantity) {
          newSerialNumbers.push(value.trim());
        }
        return newSerialNumbers;
      });
      setCurrentSerial('');
      setDuplicateSerial(false);
    } else {
      setDuplicateSerial(true);
    }
  };

  const handleQuantityChange = (value) => {
    setQuantity(Number(value));
    setSerialNumbers([]);
  };

  const handleDeleteSerialNumber = (index) => {
    setSerialNumbers((prev) => prev.filter((_, i) => i !== index));
    setQuantity((prev) => prev + 0);
  };

  const handleAddProduct = async () => {
    if (!category || !productName || !buyingPrice || !sellingPrice || !quantity || !selectedShop || !receivedBy || serialNumbers.length !== quantity) {
      setError('Please ensure all fields are filled and the number of serial numbers matches the quantity.');
      return;
    }

    setError('');

    const fullProductName = category.toLowerCase() === 'smartphone' || category.toLowerCase() === 'tablets'
      ? `${productName}`
      : productName;

    const newProduct = {
      category: category.toLowerCase(),
      productName: fullProductName.toLowerCase(),
      buyingPrice: parseFloat(buyingPrice),
      sellingPrice: parseFloat(sellingPrice),
      serialNumbers,
      quantity: parseInt(quantity, 10),
      receivedDate: Timestamp.fromDate(new Date()),
      receivedBy,
      shop: selectedShop.toLowerCase(),
      status: 'pending',
    };

    // Check if we are updating an existing product
    if (editingIndex !== null) {
      const updatedProducts = [...receivedProducts];
      updatedProducts[editingIndex] = newProduct;
      setReceivedProducts(updatedProducts);
      setproductss(updatedProducts);
      setEditingIndex(null);
    } else {
      const existingProductIndex = receivedProducts.findIndex(
        (product) => product.productName === newProduct.productName && product.shop === newProduct.shop
      );

      if (existingProductIndex !== -1) {
        const existingProduct = receivedProducts[existingProductIndex];
        existingProduct.serialNumbers = arrayUnion(...existingProduct.serialNumbers, ...newProduct.serialNumbers);
        existingProduct.quantity += newProduct.quantity;
        setReceivedProducts([...receivedProducts]);
        setproductss([...receivedProducts]);
      } else {
        setReceivedProducts((prev) => [...prev, newProduct]);
        setproductss((prev) => [...prev, newProduct]);
      }
    }
    setsubmit(false);

    // Clear form fields
    setCategory('');
    setProductName('');
    setBuyingPrice('');
    setSellingPrice('');
    setSerialNumbers([]);
    setQuantity(1);
    setSelectedShop('');
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...receivedProducts];
    updatedProducts.splice(index, 1);
    setReceivedProducts(updatedProducts);
    setproductss(updatedProducts);
  };

  const handleEditProduct = (index) => {
    const productToEdit = receivedProducts[index];
    setCategory(productToEdit.category);
    setProductName(productToEdit.productName);
    setBuyingPrice(productToEdit.buyingPrice);
    setSellingPrice(productToEdit.sellingPrice);
    setSerialNumbers(productToEdit.serialNumbers);
    setQuantity(productToEdit.quantity);
    setSelectedShop(productToEdit.shop);
    setEditingIndex(index);
  };
  const tableRef = useRef(null);

  const handlePrint = (products) => {
   
  
    const printContent = `
      <div style="text-align: center; margin: 20px;">
        <!-- Add the logo at the top -->
        <div style="margin-bottom: 20px;">
          <img src="${logo}" alt="Logo" style="width: 150px;" />
        </div>
        
        <!-- Add the title below the logo -->
        <h1 style="margin-bottom: 20px;">Stock Distribution</h1>
    
        <!-- Display the table with alternating row colors -->
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 50px;">
          <thead>
            <tr>
              <th style="border: 1px solid #dddddd; padding: 8px;">Category</th>
              <th style="border: 1px solid #dddddd; padding: 8px;">Product Name</th>
               <th style="border: 1px solid #dddddd; padding: 8px;">Serial Numbers</th>
              <th style="border: 1px solid #dddddd; padding: 8px;">Buying Price</th>
              <th style="border: 1px solid #dddddd; padding: 8px;">Selling Price</th>
              <th style="border: 1px solid #dddddd; padding: 8px;">Quantity</th>
              <th style="border: 1px solid #dddddd; padding: 8px;">Shop</th>
            </tr>
          </thead>
          <tbody>
          ${products.map((product, index) => `
            <tr style="background-color: ${index % 2 === 0 ? '#f9f9f9' : '#ffffff'};">
              <td style="border: 1px solid #dddddd; padding: 8px;">${product.category}</td>
              <td style="border: 1px solid #dddddd; padding: 8px;">${product.productName}</td>
              <td style="border: 1px solid #dddddd; padding: 8px;">
                ${product.serialNumbers.map((serial, i) => `${serial}`).join('<br>')}
              </td>
              <td style="border: 1px solid #dddddd; padding: 8px;">${product.buyingPrice}</td>
              <td style="border: 1px solid #dddddd; padding: 8px;">${product.sellingPrice}</td>
              <td style="border: 1px solid #dddddd; padding: 8px;">${product.quantity}</td>
              <td style="border: 1px solid #dddddd; padding: 8px;">${product.shop}</td>
            </tr>
          `).join('')}
          
          </tbody>
        </table>
    
        <!-- Add the footer with receiver details and date -->
        <div style="position: absolute; bottom: 0; width: 100%; text-align: center;">
          <p>Received by: ${receivedBy}</p>
          <p>Signature: ___________________</p>
          <p>${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}</p>
        </div>
      </div>
    `;
  
    const originalContent = document.body.outerHTML;
  
    document.body.outerHTML = printContent;
  
    window.print();
    window.document.close();
    document.body.outerHTML = originalContent;
  };
  
  

  const handleSubmitAll = async () => {
    const groupedProducts = receivedProducts.reduce((acc, product) => {
      if (!acc[product.shop]) acc[product.shop] = [];
      acc[product.shop].push(product);
      return acc;
    }, {});
  
    for (const shop in groupedProducts) {
      const products = groupedProducts[shop];
      for (const product of products) {
        const inventoryCollectionRef = collection(db, 'inventory');
        const newInventoryProduct = {
          ...product,
          totalItems: product.quantity,
        };
        await addDoc(inventoryCollectionRef, newInventoryProduct);
  
    
      }
    }
    setsubmit(true);
    setReceivedProducts([]);
    handlePrint(productss);
   
   
    // Clear all received products after submission to inventory
    
   
    toast.success('Product received successfully!');
  };
  
  

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Receive Products
      </Typography>
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select value={category} onChange={handleCategoryChange}>
              {categories.map((cat) => (
                <MenuItem key={cat.id} value={cat.name.toLowerCase()}>
                  {cat.name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Product Name</InputLabel>
            <Select value={productName} onChange={handleProductNameChange}>
              {filteredProducts.map((prod) => (
                <MenuItem key={prod.id} value={prod.name.toLowerCase()}>
                  {prod.name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Buying Price"
            fullWidth
            value={buyingPrice}
            onChange={(e) => setBuyingPrice(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">KSh</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Selling Price"
            fullWidth
            value={sellingPrice}
            onChange={(e) => setSellingPrice(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">KSh</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Quantity"
            fullWidth
            value={quantity}
            onChange={(e) => handleQuantityChange(e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Shop</InputLabel>
            <Select value={selectedShop} onChange={(e) => setSelectedShop(e.target.value)}>
              {shops.map((shop) => (
                <MenuItem key={shop.id} value={shop.shopName.toLowerCase()}>
                  {shop.shopName.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Serial Numbers"
            fullWidth
            value={currentSerial}
            onChange={(e) => setCurrentSerial(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ',') {
                e.preventDefault();
                handleSerialNumberChange(currentSerial);
              }
            }}
            InputProps={{
              endAdornment: (
                <Button variant="contained" onClick={() => handleSerialNumberChange(currentSerial)}>
                  Add Serial
                </Button>
              ),
            }}
          />
          {duplicateSerial && <Alert severity="warning">Duplicate Serial Number</Alert>}
          <Typography variant="caption">Press Enter or Comma to add serial numbers.</Typography>
          <Box>
            {serialNumbers.map((serial, index) => (
              <Paper key={index} style={{ display: 'inline-block', margin: '5px', padding: '10px' }}>
                {serial}
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteSerialNumber(index)}
                  style={{ marginLeft: '10px' }}
                >
                  <DeleteOutline />
                </IconButton>
              </Paper>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleAddProduct}>
            {editingIndex !== null ? 'Update Product' : 'Add Product'}
          </Button>
        </Grid>
      </Grid>
      <div>
      <TableContainer component={Paper} style={{ marginTop: '20px' }} ref={tableRef}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Selling Price</TableCell>
              <TableCell>Serial Numbers</TableCell>
              <TableCell>Shop</TableCell>
              <TableCell>Received By</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receivedProducts.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.category}</TableCell>
                <TableCell>{product.productName}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>{product.sellingPrice}</TableCell>
                <TableCell>{product.serialNumbers.join(', ')}</TableCell>
                <TableCell>{product.shop}</TableCell>
                <TableCell>{product.receivedBy}</TableCell>
                <TableCell>
                  <IconButton aria-label="edit" onClick={() => handleEditProduct(index)}>
                    <Edit />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={() => handleDeleteProduct(index)}>
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  
    </div>

    
      <Button disabled={issubmit} variant="contained" color="primary" onClick={handleSubmitAll} style={{ marginTop: '20px', marginLeft: '20px' }}>
        Submit All
      </Button>
    </Box>
  );
}

export default ReceiveProductPage;
