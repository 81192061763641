import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { collection, getDocs, query, where, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Box, CssBaseline, Switch, FormControlLabel,Tooltip, IconButton, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TaskAlt from "@mui/icons-material/TaskAltOutlined";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";

const getMuiTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: '#4CAF50',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#ffffff',
          '&:hover': {
            backgroundColor: '#388E3C',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          fontSize: '0.725rem',
        },
        head: {
          fontSize: '0.78rem',
          backgroundColor: '#FFFFFF',
          color: '#000000',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: darkMode ? '#333333' : '#f5f5f5',
          },
          '&:nth-of-type(even)': {
            backgroundColor: darkMode ? '#444444' : '#ffffff',
          },
        },
      },
    },
  },
});

const DevicesPurchases = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState(['All']);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [darkMode, setDarkMode] = useState(false);
  const [isSelectable, setIsSelectable] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [generatorName, setGeneratorName] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const renderStatusIcon = (status) => {
    switch (status) {
      case "approved":
        return (
          <Tooltip title="Approved">
            <TaskAlt style={{ color: "green", fontSize: 14 }} />
          </Tooltip>
        );
      case "pending":
        return (
          <Tooltip title="Pending">
            <HistoryIcon style={{ color: "grey", fontSize: 14 }} />
          </Tooltip>
        );
      case "rejected":
        return (
          <Tooltip title="Rejected">
            <CloseIcon style={{ color: "red", fontSize: 14 }} />
          </Tooltip>
        );
      default:
        return null;
    }
  };
 
  const columns = [
    { name: "id", label: "#", options: { filter: false, sort: false } },
    { name: "shop", label: "Shop", options: { filter: false, sort: false } },
    {
      name: "receivedDate",
      label: "Received Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(value.seconds * 1000);
            return date.toLocaleDateString();
          }
          return "";
        },
      },
    },
    { name: "category", label: "Category", options: { filter: true, sort: true } },
    { name: "productName", label: "Product", options: { filter: true, sort: true } },
    { name: "buyingPrice", label: "Buying Price", options: { filter: true, sort: false } },
    { name: "sellingPrice", label: "Selling Price", options: { filter: true, sort: false } },
    { name: "totalItems", label: "Items Received", options: { filter: true, sort: false } },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => renderStatusIcon(value),
      },
    },
    {
      name: "docId",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div>
            <IconButton onClick={() => handleUpdate(tableMeta.rowData)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => openDeleteConfirmDialog([tableMeta.rowData])}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    },
  ];

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const categoryData = querySnapshot.docs.map((doc) => doc.data().name);
    setCategories(['All', ...categoryData]);
  };

  const fetchGeneratorName = async () => {
    const userEmail = 'user'; // Replace with actual authenticated user email
    const q = query(collection(db, "employees"), where("email", "==", userEmail));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const employeeData = querySnapshot.docs[0].data();
      setGeneratorName(employeeData.name || 'Unknown');
    }
  };

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "inventory"));
    const productData = querySnapshot.docs.map((doc, index) => ({
      id: index + 1,
      docId: doc.id, // Keep track of the document ID
      ...doc.data()
    }));
    setData(productData);
    calculateTotals(productData);
  };

  const calculateTotals = (products) => {
    const filteredProducts = products.filter((item) => {
      const receivedDate = new Date(item.receivedDate.seconds * 1000);
      return (
        (!startDate || receivedDate >= new Date(startDate)) &&
        (!endDate || receivedDate <= new Date(endDate))
      );
    }).filter((item) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return (
        item.id.toString().includes(lowerCaseSearchTerm) ||
        item.shop.toLowerCase().includes(lowerCaseSearchTerm) ||
        new Date(item.receivedDate.seconds * 1000).toLocaleDateString().includes(lowerCaseSearchTerm) ||
        item.category.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.productName.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.buyingPrice.toString().includes(lowerCaseSearchTerm) ||
        item.sellingPrice.toString().includes(lowerCaseSearchTerm) ||
        item.totalItems.toString().includes(lowerCaseSearchTerm)
      );
    });

    const total = filteredProducts.reduce((sum, item) => sum + ((item.buyingPrice * item.totalItems) || 0), 0);

    setFilteredData(filteredProducts);
    setTotalPurchases(total);
  };

  const openDeleteConfirmDialog = (rows) => {
    setSelectedRows(rows);
    setOpenConfirmDialog(true);
  };


  const handleConfirmDelete = async () => {
    const deletePromises = selectedRows.map(async (row) => {
      const docId = row[8]; // Accessing the second to last element which is the docId
      await deleteDoc(doc(db, "inventory", docId));
    });

    await Promise.all(deletePromises);
    toast.success('Product(s) deleted successfully');
    setOpenConfirmDialog(false);
    fetchData();
  };

  const handleUpdate = (rowData) => {
    // Implement update logic here
  };

  useEffect(() => {
    fetchCategories();
    fetchData();
    fetchGeneratorName();
  }, []);

  useEffect(() => {
    calculateTotals(data);
  }, [startDate, endDate, searchTerm]);

  return (
    <ThemeProvider theme={getMuiTheme(darkMode)}>
      <CssBaseline />
      <Box sx={{ p: 2 }}>
        <FormControlLabel
          control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
          label="Dark Mode"
        />
        <FormControlLabel
          control={<Switch checked={isSelectable} onChange={() => setIsSelectable(!isSelectable)} />}
          label="Enable Row Selection"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <TextField
            type="date"
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          backgroundColor="green"
          startIcon={<RefreshIcon />}
          onClick={fetchData}
          sx={{ mb: 2 }}
        >
          Refresh Data
        </Button>
        
        <MUIDataTable
          title={"Devices Purchases"}
          data={filteredData}
          columns={columns}
          
          options={{
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 10, 25, 50, 100],
            selectableRows: isSelectable ? 'multiple' : 'none',
            onRowsDelete: (rowsDeleted) => openDeleteConfirmDialog(rowsDeleted.data.map((row) => data[row.dataIndex])),
          }}
        />
        <Typography variant="h8" gutterBottom>
          Total Purchases: {totalPurchases}
        </Typography>
        <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete the selected product(s)?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="primary">Delete</Button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
      </Box>
    </ThemeProvider>
  );
};

export default DevicesPurchases;
