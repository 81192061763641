import React, { useState, useEffect } from 'react';
import { Tabs, Tab,Typography, Box, CircularProgress, Select, MenuItem, FormControl, InputLabel, Modal, TextField, Button, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { collection, getDocs, query, where, Timestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';  // Adjust import path based on your file structure
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Edit, Delete,Print } from '@mui/icons-material';
import { toast } from 'react-toastify';


const SalesReportPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [salesData, setSalesData] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [role, setRole] = useState('');
  const [currentSale, setCurrentSale] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [editedSale, setEditedSale] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customerCategoryFilter, setCustomerCategoryFilter] = useState('All');
  const [salespersonFilter, setSalespersonFilter] = useState('');
  const [assignedShop, setAssignedShop] = useState('');
  const [shopFilter, setShopFilter] = useState('');
  const [shops, setShops] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
 
  const [analyticsData, setAnalyticsData] = useState({
    totalSales: 0,
    salesByCategory: {},
    salesTrends: {
      labels: [],
      data: []
    }
  });

  // Fetch role, assignedShop, and shops
 

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const shopListRef = collection(db, 'shoplist');
        const snapshot = await getDocs(shopListRef);
        const shopNames = snapshot.docs.map(doc => doc.data().shopName);
        setShops(shopNames);
      } catch (error) {
        console.error('Error fetching shops:', error);
      }
    };

    fetchShops();
  }, []);

  useEffect(() => {
  const fetchEmployeeDatas = async () => {
    try {
      const userEmail = auth.currentUser.email;
      const employeeRef = collection(db, 'employees');
      const q = query(employeeRef, where('email', '==', userEmail));
      const employeeSnapshot = await getDocs(q);

      if (!employeeSnapshot.empty) {
        const employeeData = employeeSnapshot.docs[0].data();
        setRole(employeeData.role);
        setAssignedShop(employeeData.assignedShop);

        let fetchedEmployees = [];

        if (employeeData.role === 'admin') {
          // Fetch all employees
          const allEmployeesSnapshot = await getDocs(employeeRef);
          fetchedEmployees = allEmployeesSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
            role: doc.data().role,
            email: doc.data().email,
            assignedShop: doc.data().assignedShop,
          }));
        } else if (employeeData.role === 'shopmanager') {
          // Fetch employees where assignedShop matches the manager's shop
          const shopEmployeesQuery = query(employeeRef, where('assignedShop', '==', employeeData.assignedShop));
          const shopEmployeesSnapshot = await getDocs(shopEmployeesQuery);
          fetchedEmployees = shopEmployeesSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
            role: doc.data().role,
            email: doc.data().email,
            assignedShop: doc.data().assignedShop,
          }));
        }

        setEmployees(fetchedEmployees);
      }
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  fetchEmployeeDatas();
}, [employees]);


  // Fetch Employee Data
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const userEmail = auth.currentUser.email;
        const employeeRef = collection(db, 'employees');
        const q = query(employeeRef, where('email', '==', userEmail));
        const employeeSnapshot = await getDocs(q);

        if (!employeeSnapshot.empty) {
          const employeeData = employeeSnapshot.docs[0].data();
          setRole(employeeData.role);
          setAssignedShop(employeeData.assignedShop);

          if (employeeData.role === 'admin') {
            fetchSalesData(shops);  // Pass the shops array
          } else if (employeeData.role === 'shopmanager') {
            fetchSalesData([employeeData.assignedShop]);  // Pass as array for consistency
          }
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (shops.length > 0) {
      fetchEmployeeData();
    }
  }, [shops]);  // Adding `shops` as a dependency to ensure it's populated

const fetchSalesData = async (shopList) => {
  let allSalesData = [];

  for (const shop of shopList) {
    // Reference to the mpesa_sales and credit_sales collections for the current shop
    const mpesaSalesRef = collection(db, `sales/${shop}/mpesa_sales`);
    const creditSalesRef = collection(db, `sales/${shop}/credit_sales`);
  
    // Fetch the documents from both collections concurrently
    const [mpesaSnapshot, creditSnapshot] = await Promise.all([
      getDocs(mpesaSalesRef),
      getDocs(creditSalesRef),
    ]);
  
    // Map the documents to include the shop name and merge them into the allSalesData array
    const mpesaSales = mpesaSnapshot.docs.map(doc => ({
      ...doc.data(),
      shop: shop, // Add shop field
    }));
  
    const creditSales = creditSnapshot.docs.map(doc => ({
      ...doc.data(),
      shop: shop, // Add shop field
    }));
  
    // Merge the sales data for the current shop into the allSalesData array
    allSalesData = [...allSalesData, ...mpesaSales, ...creditSales];
  }
  
  

  const products = allSalesData.flatMap(sale => 
    sale.products.map(product => ({
      ...product,
      saleId: sale.id,
      customerCategory: sale.customerCategory,
      customerName: sale.customerName,
      customerPhone: sale.customerPhone,
      discount: sale.discount,
      paymentMethod: sale.paymentMethod,
      saleTimestamp: sale.saleTimestamp.toDate(),
      salesperson: sale.salesperson,
      total: sale.total,
      shop: sale.shop,
      margin: product.price - product.buyingprice  // Calculate margin here
    }))
  );

  setSalesData(products);
  setLoading(false);
};

  const handlePrintInvoice = (rowData) => {
    // Map the rowData to the structure needed for printing
    const saleData = {
      customerphone: rowData[2],
      customerCategory: rowData[0], // Assuming 'Customer Category' is at index 0
      customerName: rowData[1], // Assuming 'Customer Name' is at index 1
      salesperson: rowData[4], // Assuming 'Salesperson' is at index 4
      name: rowData[6], // Assuming 'Name' is at index 6
      quantity: rowData[7], // Assuming 'Quantity' is at index 7
      price: rowData[8], // Assuming 'Price' is at index 8
      serial:role === 'admin' ? rowData[11] : rowData[9], // Assuming 'Serial' is at index 9
    };
     // Generate a random receipt number between 1 and 9999, padded with leading zeros
     const receiptNumber = Math.floor(Math.random() * 9999 + 1).toString().padStart(4, '0');

     // Get the current date and time
     const currentDate = new Date();
     const formattedDate = currentDate.toLocaleDateString(); // Format the date as needed
     const formattedTime = currentDate.toLocaleTimeString(); // Format the time as needed
 
  
    const printerWidth = localStorage.getItem('printerWidth') || 80;
  
    const printContent = `
      <div style="text-align:center; justify-content: center;">
        <h2>Safaricom Customer Care</h2>
       <h3>Cowtrack ${assignedShop.substring(0,1).toUpperCase()}${assignedShop.substring(1)} Shop</h3>
        
       <p>Tel: 0728262444</p>
         <div style="display:flex;text-align:center;justify-content: center;" >
       <p><strong>Customer Name: </strong></p>
       <p style="margin-start: 5px">${saleData.customerName.toUpperCase()}</p>
       </div>
      <div style="display:flex;justify-content: center;">
       <p style=""><strong>Customer Phone: </strong></p>
       <p style="margin-start: 5px">${saleData.customerphone}</p>
      </div>
      </div>
      <div style=" text-align: center; justify-content: space-between; ">
      
       <div style="display:flex; justify-content: space-between; margin-top: 10px;">
        <p style="margin-right: 10px;"><strong>Receipt No: </strong>${receiptNumber}</p>
        <p style="margin-right: 10px;"><strong>Date: </strong>${formattedDate}</p>
        <p><strong>Time: </strong>${formattedTime}</p>
      </div>

      </div>
      <table style="width:100%; text-align:center; margin-top:20px; border-collapse: collapse;">
        <thead>
          <tr style="border-bottom: 1px solid #000;">
            <th style="border-bottom: 1px solid #000;">Item Name</th>
            <th style="border-bottom: 1px solid #000;">Serial Number</th>
            <th style="border-bottom: 1px solid #000;">Quantity</th>
            <th style="border-bottom: 1px solid #000;">Price (ksh)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: none;">${saleData.name}</td>
            <td style="border: none;">${saleData.serial}</td>
            <td style="border: none;">${saleData.quantity}</td>
            <td style="border: none;">${saleData.price}</td>
          </tr>
        <tr>
          <td colspan="2" style="text-align: right;"><strong>Total Quantity:</strong></td>
          <td><strong>${saleData.quantity}</strong></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2" style="text-align: right;"><strong>Total Price:</strong></td>
          <td><strong>ksh${saleData.price.toFixed(2)}</strong></td>
          <td></td>
        </tr>
      </table>
      <p>Served By: ${saleData.salesperson}</p>
      <p style="text-align:center; margin-top:20px;">Thank you for shopping with us!</p>
    `;
  
    const printWindow = window.open('', '', `width=${printerWidth * 10}`);
  
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.print();
    // Automatically close the print window after printing
    printWindow.onload = function() {
    
      printWindow.onafterprint = function() {
        printWindow.close();
      };
    };
  };

  const filteredSalesData = salesData.filter(sale => {
    const saleDate = new Date(sale.saleTimestamp); // Assuming the sales data has a 'date' field
  
    return (
      (
        // Check date filters only if they are set
        (!startDate || saleDate >= startDate) &&
        (!endDate || saleDate <= endDate)
      ) &&
      (
        // Filter by customer category only if it's not 'All'
        (customerCategoryFilter === 'All' || sale.customerCategory === customerCategoryFilter)
      ) &&
      (
        // Filter by salesperson only if a filter is set
        (!salespersonFilter || sale.salesperson === salespersonFilter)
      ) &&
      (
        // Filter by shop only if a filter is set
        (!shopFilter || sale.shop === shopFilter)
      )
    );
  });
  



  const totalSales = filteredSalesData.reduce((total, sale) => total + (sale.price * sale.quantity), 0);
  const totalMargin = filteredSalesData.reduce((margin,sale)=> margin + ((sale.price * sale.quantity)-(sale.buyingprice * sale.quantity)),0);

  const adminColumns = [
    { name: 'customerCategory', label: 'Customer Category' },
    { name: 'customerName', label: 'Customer Name' },
    { name: 'customerPhone', label: 'Customer Phone', display: false },  // Hidden
    { name: 'paymentMethod', label: 'Payment Method', display: false },  // Hidden
    { name: 'salesperson', label: 'Salesperson' },
    { name: 'category', label: 'Category', display: false },  // Hidden
    { name: 'name', label: 'Name' },
    { name: 'quantity', label: 'Quantity', display: false },  // Hidden
    { name: 'price', label: 'Price' },
    { name: 'buyingprice', label: 'Buying Price' },
    { name: 'margin', label: 'Margin' },
    { name: 'serial', label: 'Serial', display: false },  // Hidden
    { name: 'shop', label: 'Shop', display: true },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
             <Tooltip title="Print Receipt">
              <Print onClick={() => handlePrintInvoice(tableMeta.rowData)} style={{ cursor: 'pointer' }} />
            </Tooltip>
            <Tooltip title="Edit">
              <Edit onClick={() => handleEdit(tableMeta.rowData)} style={{ cursor: 'pointer', marginRight: 0}} />
            </Tooltip>
            <Tooltip title="Delete">
              <Delete onClick={() => handleClickOpen(tableMeta.rowData)} style={{ cursor: 'pointer' }} />
            </Tooltip>
          </div>
        ),
      },
    },
  ];
  const handleClickOpen = (sale) => {
    setSelectedSale(sale);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSale(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedSale) return;

    try {
      if (selectedSale.customerCategory !== 'Walkin') {
        await deleteDoc(doc(db, 'credit_sales', selectedSale.saleId));
      } else {
        await deleteDoc(doc(db, 'sales', selectedSale.saleId));
      }

      setSalesData(prev => prev.filter(s => s.saleId !== selectedSale.saleId));
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error('Error deleting sale:', error);
    }
  };
  const nonAdminColumns = [
    { name: 'customerCategory', label: 'Customer Category' },
    { name: 'customerName', label: 'Customer Name' },
    { name: 'customerPhone', label: 'Customer Phone', display: false },  // Hidden
    { name: 'paymentMethod', label: 'Payment Method', display: false },  // Hidden
    { name: 'salesperson', label: 'Salesperson' },
    { name: 'category', label: 'Category', display: false },  // Hidden
    { name: 'name', label: 'Name' },
    { name: 'quantity', label: 'Quantity', display: false },  // Hidden
    { name: 'price', label: 'Price' },
    { name: 'serial', label: 'Serial', display: false },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
             <Tooltip title="Print Receipt">
              <Print onClick={() => handlePrintInvoice(tableMeta.rowData)} style={{ cursor: 'pointer' }} />
            </Tooltip>
          
          </div>
        ),
      },
    },
  ];
  const columns = role === 'admin' ? adminColumns : nonAdminColumns;
  

  const handleEdit = (sale) => {
    setCurrentSale(sale);
    setEditedSale({
      customerCategory: sale.customerCategory,
      customerName: sale.customerName,
      customerPhone: sale.customerPhone,
      paymentMethod: sale.paymentMethod,
      salesperson: sale.salesperson,
      category: sale.category,
      name: sale.name,
      quantity: sale.quantity,
      price: sale.price,
      serial: sale.serial,
      
    });
    setOpenEditModal(true);
  };

  
  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
    selectableRows: 'none',
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
    onRowClick: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
    },
    rowsPerPageOptions: [5,10, 25, 50],
    rowsPerPage: 5,
   
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const handleSaveEdit = async () => {
    try {
      await updateDoc(doc(db, 'sales', assignedShop, 'credit_sales', currentSale.saleId), editedSale);
      setSalesData(prevData =>
        prevData.map(sale =>
          sale.saleId === currentSale.saleId ? { ...sale, ...editedSale } : sale
        )
      );
      setOpenEditModal(false);
    } catch (error) {
      console.error('Error updating sale:', error);
    }
  };
  const getMuiTheme = (darkMode) => createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#4CAF50',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: '#00000',
            backgroundColor: '#ffffff',
            '&:hover': {
              backgroundColor: '#388E3C',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '4px 8px',
            fontSize: '0.725rem',
          },
          head: {
            fontSize: '0.78rem',
            backgroundColor: '#FFFFFF',
            color: '#000000',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: darkMode ? '#333333' : '#f5f5f5',
            },
            '&:nth-of-type(even)': {
              backgroundColor: darkMode ? '#444444' : '#ffffff',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={getMuiTheme(darkMode)}>      
      <Box margin={2}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2, p: 3 }}>
  <TextField
    label="Start Date"
    type="date"
    value={startDate ? startDate.toISOString().substring(0, 10) : ''}
    onChange={(e) => setStartDate(e.target.value ? new Date(e.target.value) : null)}
    InputLabelProps={{ shrink: true }}
  />
  <TextField
    label="End Date"
    type="date"
    value={endDate ? endDate.toISOString().substring(0, 10) : ''}
    onChange={(e) => setEndDate(e.target.value ? new Date(e.target.value) : null)}
    InputLabelProps={{ shrink: true }}
  />
  <FormControl>
    <InputLabel>Customer Category</InputLabel>
    <Select
      value={customerCategoryFilter}
      onChange={(e) => setCustomerCategoryFilter(e.target.value)}
      label="Customer Category"
    >
      <MenuItem value="All">All</MenuItem>
      <MenuItem value="LMM">LMM</MenuItem>
      <MenuItem value="Watu Simu">Watu Simu</MenuItem>
      <MenuItem value="Onfone">Onfone</MenuItem>
      <MenuItem value="Walkin">Walkin</MenuItem>
    </Select>
  </FormControl>
 <FormControl>
  <InputLabel>Salesperson</InputLabel>
  <Select
    value={salespersonFilter}
    onChange={(e) => setSalespersonFilter(e.target.value)}
    label="Salesperson"
  >
    <MenuItem value="">All</MenuItem>
    {employees.map((employee, index) => (
      <MenuItem key={employee.id} value={employee.name}>
        {employee.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

  <FormControl>
    <InputLabel> Filter by Shop</InputLabel>
            <Select
              value={shopFilter}
              onChange={(e) => setShopFilter(e.target.value)}
              label="Filter by Shop"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {shops.map((shop, index) => (
                <MenuItem key={index} value={shop}>
                  {shop}
                </MenuItem>
              ))}
            </Select>

  </FormControl>
</Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <MUIDataTable
            title={`Sales Report`}
            data={filteredSalesData} 
            columns={columns}
            options={options}
          />
        )}
         <Box display="flex" >
           <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: 12, marginTop: 20 }}>
            Total Sales: {totalSales}
          </Typography>
          <Typography  variant="subtitle1" style={{ fontWeight: 'bold', fontSize: 12, margin: 20 }}>
          {role !== 'admin' ?  '':`Total Profit: ${totalMargin} ` }

          </Typography>
          </Box>
      </Box>

      {/* Edit Sale Modal */}
      <Modal open={openEditModal} onClose={handleEditModalClose}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            boxShadow: 24,
            padding: 16
          }}
        >
          <h2>Edit Sale</h2>
          <form>
            <TextField
              label="Customer Category"
              value={editedSale.customerCategory}
              onChange={(e) => setEditedSale({ ...editedSale, customerCategory: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Customer Name"
              value={editedSale.customerName}
              onChange={(e) => setEditedSale({ ...editedSale, customerName: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Customer Phone"
              value={editedSale.customerPhone}
              onChange={(e) => setEditedSale({ ...editedSale, customerPhone: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Payment Method"
              value={editedSale.paymentMethod}
              onChange={(e) => setEditedSale({ ...editedSale, paymentMethod: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Salesperson"
              value={editedSale.salesperson}
              onChange={(e) => setEditedSale({ ...editedSale, salesperson: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Category"
              value={editedSale.category}
              onChange={(e) => setEditedSale({ ...editedSale, category: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Name"
              value={editedSale.name}
              onChange={(e) => setEditedSale({ ...editedSale, name: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quantity"
              value={editedSale.quantity}
              onChange={(e) => setEditedSale({ ...editedSale, quantity: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Price"
              value={editedSale.price}
              onChange={(e) => setEditedSale({ ...editedSale, price: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Serial"
              value={editedSale.serial}
              onChange={(e) => setEditedSale({ ...editedSale, serial: e.target.value })}
              fullWidth
              margin="normal"
            />
            <Box mt={2} textAlign="center">
              <Button variant="contained" color="primary" onClick={handleSaveEdit}>
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
   </ThemeProvider>   

   
  
  );
};

export default SalesReportPage;
