import React, { useState } from 'react';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const AccessoriesPurchases = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const purchases = [
    { category: 'Charger', product: 'Fast Charger', serialNumber: 'SN11111', buyingPrice: 50, date: '2024-07-01' },
    { category: 'Earphones', product: 'Wireless Earphones', serialNumber: 'SN22222', buyingPrice: 30, date: '2024-07-03' },
    { category: 'Case', product: 'Phone Case', serialNumber: 'SN33333', buyingPrice: 20, date: '2024-07-05' },
  ];

  const filteredPurchases = purchases.filter(purchase =>
    purchase.product.toLowerCase().includes(searchTerm.toLowerCase()) &&
    purchase.date >= startDate && purchase.date <= endDate
  );

  const totalPurchases = filteredPurchases.reduce((total, purchase) => total + purchase.buyingPrice, 0);

  const printReport = () => {
    window.print();
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text("Accessories Purchases", 10, 10);
    let yPosition = 20;
    filteredPurchases.forEach((purchase, index) => {
      doc.text(`${index + 1}. ${purchase.category} - ${purchase.product} - ${purchase.serialNumber} - ${purchase.buyingPrice} - ${purchase.date}`, 10, yPosition);
      yPosition += 10;
    });
    doc.text(`Total Purchases: ${totalPurchases}`, 10, yPosition + 10);
    doc.save("accessories_purchases.pdf");
  };

  const exportExcel = () => {
    const data = [
      ["Category", "Product", "Serial Number", "Buying Price", "Date"],
      ...filteredPurchases.map(purchase => [purchase.category, purchase.product, purchase.serialNumber, purchase.buyingPrice, purchase.date]),
      ["", "", "Total Purchases", totalPurchases, ""]
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accessories Purchases");
    XLSX.writeFile(workbook, "accessories_purchases.xlsx");
  };

  return (
    <div className="container">
      <header className="d-flex justify-content-between align-items-center my-3">
        <h1>Accessories Purchases</h1>
        <div>
          <button className="btn btn-primary mx-1" onClick={printReport}>
            <i className="fas fa-print"></i> Print
          </button>
          <button className="btn btn-danger mx-1" onClick={exportPDF}>
            <i className="fas fa-file-pdf"></i> Export as PDF
          </button>
          <button className="btn btn-success mx-1" onClick={exportExcel}>
            <i className="fas fa-file-excel"></i> Export as Excel
          </button>
        </div>
      </header>
      <div className="mb-3 d-flex">
        <input
          type="date"
          className="form-control me-2"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className="form-control me-2"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Search purchases..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <main>
        <section id="reportSection">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Category</th>
                <th>Product</th>
                <th>Serial Number</th>
                <th>Buying Price</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredPurchases.map((purchase, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{purchase.category}</td>
                  <td>{purchase.product}</td>
                  <td>{purchase.serialNumber}</td>
                  <td>{purchase.buyingPrice}</td>
                  <td>{purchase.date}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4" className="text-end"><strong>Total Purchases:</strong></td>
                <td><strong>{totalPurchases}</strong></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </section>
      </main>
      <footer className="text-center mt-4">
        <p>&copy; 2024 Accessories Purchases</p>
      </footer>
    </div>
  );
};

export default AccessoriesPurchases;

