import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Tooltip,
  ListItemText,IconButton,
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import CheckCircleIcon from '@mui/icons-material/TaskAltOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { collection,increment, arrayUnion, getDocs,getDoc,serverTimestamp, runTransaction,query, where, doc, updateDoc, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path according to your file structure
import { toast } from 'react-toastify';
import {getAuth} from 'firebase/auth';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const getMuiTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: '#4CAF50',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#ffffff',
          '&:hover': {
            backgroundColor: '#388E3C',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          fontSize: '0.725rem',
        },
        head: {
          fontSize: '0.78rem',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: darkMode ? '#333333' : 'rgba(0, 0, 0, 0.04)',
          },
          '&:nth-of-type(even)': {
            backgroundColor: darkMode ? '#444444' : '#ffffff',
          },
        },
      },
    },
  },
});

function ManageShopPage() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);  // Assuming you have some logic to set this
  const [rejectReason, setRejectReason] = useState('');
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedShopName, setSelectedShopName] = useState('');

  const [shops, setShops] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedShop, setSelectedShop] = useState('');
  const [email, setemail] = useState('');
  const [selectedFabrics, setSelectedFabrics] = useState([]);
  const [shopDetails, setShopDetails] = useState({});
  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [openStockDialog, setOpenStockDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [stockDetails, setStockDetails] = useState({});
  const [pendingProducts, setPendingProducts] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState([]);
 

  useEffect(() => {
  
    
  
    fetchData();
  }, []);
 
  const fetchData = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const employeeQuery = query(
        collection(db, 'employees'),
        where('email', '==', user.email)
      );
      setemail(user.email);
      const employeeSnapshot = await getDocs(employeeQuery);
      const employeeData = employeeSnapshot.docs[0].data();
  
      const { role, assignedShop } = employeeData;
  
      let inventoryProducts = [];
      role === 'admin' ? setIsAdmin(true) : setIsAdmin(false);
  
      if (role === 'admin') {
        // Fetch all products from the inventory collection
        const inventoryCollectionRef = collection(db, 'inventory');
        const inventorySnapshot = await getDocs(inventoryCollectionRef);
        inventoryProducts = inventorySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } else if (role === 'shopmanager' || role === 'salesperson') {
        // Fetch products where status is 'pending' or 'rejected' and shop matches assignedShop
        const inventoryCollectionRef = collection(db, 'inventory');
        const shopManagerQuery = query(
          inventoryCollectionRef,
          where('shop', '==', assignedShop),
          where('status', 'in', ['pending', 'rejected'])
        );
        const shopManagerSnapshot = await getDocs(shopManagerQuery);
        inventoryProducts = shopManagerSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      }
  
      // Set the fetched inventory products to state
      setPendingProducts(inventoryProducts);
  
      // Fetch and set shops with shopName
      const shopsCollection = collection(db, 'shoplist');
      const shopsSnapshot = await getDocs(shopsCollection);
      setShops(shopsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  
      // Fetch and set employees
      const employeesCollection = collection(db, 'employees');
      const employeesSnapshot = await getDocs(employeesCollection);
      setEmployees(employeesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  
      // Fetch and set products
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      setProducts(productSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  
      // Fetch and set categories
      const categoryCollection = collection(db, 'categories');
      const categorySnapshot = await getDocs(categoryCollection);
      setCategories(categorySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Optionally, display an error message or toast notification
    }
  };
  const handleShopChange = (event) => {
    const selectedShopId = event.target.value;
    setSelectedShop(selectedShopId);
    const shop = shops.find((shop) => shop.id === selectedShopId);
    setShopDetails(shop || {});
    
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setStockDetails((prev) => ({ ...prev, category: selectedCategory }));
    setFilteredProducts(products.filter((product) => product.category === selectedCategory));
  };

  const handleProductChange = async (event) => {
    const selectedProductName = event.target.value;
    
    const selectedProduct = products.find((product) => product.name === selectedProductName);
    
    setStockDetails((prev) => ({
      ...prev,
      product: selectedProductName,
      buyingPrice: selectedProduct?.buyingPrice || '',
      sellingPrice: selectedProduct?.sellingPrice || '',
    }));

    if (selectedProduct && stockDetails.category && stockDetails.fromStore) {
      const serialNumbersRef = collection(
        db,
        'received_products',
        stockDetails.fromStore,
        'products'
      );
      const serialNumbersQuery = query(
        serialNumbersRef,
        where('category', '==', stockDetails.category),
        where('productName', '==', selectedProductName)
      );
      const serialNumbersSnapshot = await getDocs(serialNumbersQuery);
      if (!serialNumbersSnapshot.empty) {
        const productData = serialNumbersSnapshot.docs[0].data();
        setSerialNumbers(productData.serialNumbers || []);
      }
    }
  };

 

  // Prepare the data for MUIDataTable
  const data = pendingProducts.map((product) => ({
    productName: product.productName,
    shop: product.shop,
    id: product.id,
    quantity: product.quantity,
    serialNumbers: product.serialNumbers,
    buyingPrice: product.buyingPrice,
    sellingPrice: product.sellingPrice,
    status: product.status,
    actions: product, // Pass the entire product object for action buttons
  }));

  // MUIDataTable options
  const option = {
    filter: true,
    sort: true,
    selectableRows: 'multiple',  // Enable multiple row selection
    responsive: 'standard',
  
    customToolbarSelect: selectedRows =>{ return (
       
          <IconButton
            onClick={() => {
              
              const indexesToPrint = selectedRows.data.map((row, k) => row.dataIndex);
              let temp = [];
              
              
              for (let i = 0; i < pendingProducts.length; i++) {
                if (indexesToPrint.includes(i)) {
                  temp.push(pendingProducts[i]['id']);
                  handleApproveProduct(pendingProducts[i]['id'],pendingProducts[i]['shop'],pendingProducts[i]['productName'],pendingProducts[i]['quantity'],pendingProducts[i]['serialNumbers']);
                }
               //toast.info(temp[0]);
              }
              setSelectedFabrics(temp);
            }}
            style={{
              marginRight: "24px",
              height: "48px",
              top: "50%",
              display: "block",
              position: "relative",
              transform: "translateY(-50%)",
            }}
          >
            <span style={{marginTop: "23px"}}>Print QR Code</span> 
          </IconButton>
          
          
        );
      },
  };
  

  const options = {
    selectableRows: 'multiple',  // Enable multiple row selection
    customToolbarSelect: (selectedRows) => {
      return (
        <div>
          {/* Approve Button */}
          <Tooltip title="Approve">
            <IconButton
              disabled={isAdmin}  // Assuming isAdmin is defined somewhere in your component
              onClick={() => {
              
                const indexesToPrint = selectedRows.data.map((row, k) => row.dataIndex);
                let temp = [];
                
                
                for (let i = 0; i < pendingProducts.length; i++) {
                  if (indexesToPrint.includes(i)) {
                    temp.push(pendingProducts[i]['id']);
                    handleApproveProduct(pendingProducts[i]['id'],pendingProducts[i]['shop'],pendingProducts[i]['productName'],pendingProducts[i]['quantity'],pendingProducts[i]['serialNumbers']);
                  }
                 //toast.info(temp[0]);
                }
                fetchData();
                setSelectedFabrics(temp);
              }}
              style={{
                marginRight: "24px",
                height: "48px",
                top: "50%",
                display: "block",
                position: "relative",
                transform: "translateY(-50%)",
              }}
            >
             
              <CheckCircleIcon style={{ color: 'green' }} />
            </IconButton>
          </Tooltip>
  
          {/* Reject Button */}
          <Tooltip title="Reject">
            <IconButton
              disabled={isAdmin}  // Assuming isAdmin is defined somewhere in your component
              onClick={() => {
              
                const indexesToPrint = selectedRows.data.map((row, k) => row.dataIndex);
                let temp = [];
                
                
                for (let i = 0; i < pendingProducts.length; i++) {
                  if (indexesToPrint.includes(i)) {
                    temp.push(pendingProducts[i]['id']);
                    handleOpenRejectDialog(pendingProducts[i]['id'],pendingProducts[i]['shop']);
                  }
                 //toast.info(temp[0]);
                }
                setSelectedFabrics(temp);
              }}
              style={{
                marginRight: "24px",
                height: "48px",
                top: "50%",
                display: "block",
                position: "relative",
                transform: "translateY(-50%)",
              }}
            >
              <CancelIcon style={{ color: 'red' }} />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  };
  
  const handleSerialNumberChange = (event) => {
    const value = event.target.value;
    setSelectedSerialNumbers(value);
  };
  const transferProduct = async (fromStore, toStore, productName, selectedSerialNumbers) => {
    const fromStoreRef = collection(db, `received_products/${fromStore}/products`);
    const toStoreRef = collection(db, `received_products/${toStore}/products`);
    const transferredStockRef = collection(db, `received_products/${fromStore}/transferred_stock`);
    
    try {
      await runTransaction(db, async (transaction) => {
        // Get the product from the fromStore
        const fromStoreQuery = query(fromStoreRef, where('productName', '==', productName));
        const fromStoreSnapshot = await getDocs(fromStoreQuery);
  
        if (fromStoreSnapshot.empty) {
          toast.error('Product does not exist in the fromStore');
          throw new Error('Product does not exist in the fromStore');
        }
  
        const fromStoreDoc = fromStoreSnapshot.docs[0];
        const fromStoreData = fromStoreDoc.data();
        const remainingSerialNumbers = fromStoreData.serialNumbers.filter(
          (serial) => !selectedSerialNumbers.includes(serial)
        );
        const quantityTransferred = selectedSerialNumbers.length;
  
        // Update fromStore document
        transaction.update(fromStoreDoc.ref, {
          serialNumbers: remainingSerialNumbers,
          totalItems: fromStoreData.totalItems - quantityTransferred,
          quantity: fromStoreData.quantity - quantityTransferred,
        });
  
        // Search for the product in the toStore
        const toStoreQuery = query(toStoreRef, where('productName', '==', productName));
        const toStoreSnapshot = await getDocs(toStoreQuery);
  
        if (!toStoreSnapshot.empty) {
          // If the product exists in toStore, update it
          const toStoreDoc = toStoreSnapshot.docs[0];
          const toStoreData = toStoreDoc.data();
          transaction.update(toStoreDoc.ref, {
            serialNumbers: [...toStoreData.serialNumbers, ...selectedSerialNumbers],
            totalItems: toStoreData.totalItems + quantityTransferred,
            quantity: toStoreData.quantity + quantityTransferred,
          });
        } else {
          // If the product doesn't exist in toStore, create a new document
          const newDocRef = doc(toStoreRef); // Create a new document reference
          transaction.set(newDocRef, {
            ...fromStoreData,
            serialNumbers: selectedSerialNumbers,
            totalItems: quantityTransferred,
            quantity: quantityTransferred,
            shop: toStore,
            status: 'pending',
            receivedDate: serverTimestamp(), // Use Firebase server timestamp
          });
        }
  
        // Save to transferred_stock collection
        const newTransferDocRef = doc(transferredStockRef); // Create a new document reference
        transaction.set(newTransferDocRef, {
          fromStore,
          toStore,
          productName: fromStoreData.productName,
          serialNumbers: selectedSerialNumbers,
          status: 'pending',
          timestamp: serverTimestamp(), // Use Firebase server timestamp
        });
      });
  
      toast.success('Product transfer successful');
      // Clear the categories state
     // Clear the products state
      setStockDetails([]); // Reset stock details
  
    } catch (error) {
      toast.error(`Error during product transfer: ${error.message}`);
    }
  };
 
  
   // MUIDataTable columns
   const columns = [
    {
      name: 'productName',
      label: 'Product Name',
    },
    {
      name: 'shop',
      label: 'Shop',
    },
    {
      name: 'serialNumbers',
      label: 'S/Numbers',
      options: {
        customBodyRender: (value) => value.map((serial, index) => (
          <div key={index}>
            {index + 1}. {serial}
          </div>
        )),
      },
    },
    {
      name: 'buyingPrice',
      label: 'Buying Price',
      options: {
        sort: true,
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      name: 'sellingPrice',
      label: 'Selling Price',
      options: {
        sort: true,
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: true,
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const productId = value;
          const shopName = tableMeta.rowData[1];
          const productName = tableMeta.rowData[0];
          const quantity = tableMeta.rowData[2].length;
          const serialNumbers = tableMeta.rowData[2];

          return (
            <div>
              <Tooltip title="Approve">
                <IconButton
                  disabled={isAdmin}
                  onClick={() => handleApproveProduct(productId, shopName, productName, quantity, serialNumbers)}
                  aria-label="approve"
                >
                  <CheckCircleIcon style={{ color: 'green' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reject">
                <IconButton
                  disabled={isAdmin}
                  onClick={() => handleOpenRejectDialog(productId, shopName)}
                  aria-label="reject"
                >
                  <CancelIcon style={{ color: 'red' }} />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  // Individual Approve Product
  const handleApproveProduct = async (productId, shopName, productName, quantity, serialNumbers) => {
    try {
      const inventoryProductRef = doc(db, 'inventory', productId);
      const shopProductRef = collection(db, 'received_products', shopName, 'products');
      const shopProductQuery = query(shopProductRef, where('productName', '==', productName));
      const shopProductSnapshot = await getDocs(shopProductQuery);
      const productDoc = await getDoc(inventoryProductRef);
      const productData = productDoc.data();

      if (!shopProductSnapshot.empty) {
        const existingShopProductDoc = shopProductSnapshot.docs[0];
        await updateDoc(existingShopProductDoc.ref, {
          serialNumbers: arrayUnion(...serialNumbers),
          quantity: increment(quantity),
          totalItems: increment(quantity),
        });
      } else {
        await addDoc(shopProductRef, {
          ...productData,
          status: 'approved',
        });
      }

      await updateDoc(inventoryProductRef, { status: 'approved' });
      toast.success('Product approved and added to the shop successfully!');
    } catch (error) {
      console.error('Error approving product: ', error);
      toast.error('Failed to approve product.', error.message);
    }
  };

  // Multi Approve Selected Products
 // Multi Approve Selected Products
const handleApproveSelectedProducts = async (selectedIndexes) => {
  if (!selectedIndexes || !selectedIndexes.id) {
    toast.error('Row is undefined or missing id:', selectedIndexes.id);
    return;
  }
  for (const index of selectedIndexes) {
    const row = pendingProducts[index]; // Fetch row data from pendingProducts state
    const { id: productId, shop: shopName, productName, serialNumbers } = row;
    const quantity = serialNumbers.length;
    await handleApproveProduct(productId, shopName, productName, quantity, serialNumbers);
  }
  toast.success('Selected products approved successfully!');
};

// Multi Reject Selected Products
const handleRejectSelectedProducts = async (selectedIndexes) => {
  for (const index of selectedIndexes) {
    const row = pendingProducts[index]; // Fetch row data from pendingProducts state
    const { id: productId, shop: shopName } = row;
    await handleRejectProduct(productId, shopName);
  }
  toast.success('Selected products rejected successfully!');
};


  // Open Reject Dialog
  const handleOpenRejectDialog = (productId, shopName) => {
    setSelectedProductId(productId);
    setSelectedShopName(shopName);
    setOpenRejectDialog(true);
  };

  // Individual Reject Product
  const handleRejectProduct = async (productId, shopName) => {
    try {
      // Get the reference to the product document in the 'inventory' collection
      const productRef = doc(db, 'inventory', productId);
      
      // Fetch the document to check if it exists and matches the shop name
      const productDoc = await getDoc(productRef);
      if (!productDoc.exists()) {
        toast.error('Product not found in inventory.');
        return;
      }
      
      // Check if the shop in the document matches the given shopName
      const productData = productDoc.data();
      if (productData.shop !== shopName) {
        toast.error('Product does not belong to the specified shop.');
        return;
      }
  
      // Update the document with rejection status and reason
      await updateDoc(productRef, {
        status: 'rejected',
        rejectReason,
      });
      
      toast.success('Product rejected successfully!');
    } catch (error) {
      toast.error('Error rejecting product: ', error);
      alert('Failed to reject product.');
    }
  };
  
  
  
  
  
  return (
    <ThemeProvider theme={getMuiTheme(false)}>
    <Box sx={{ flexGrow: 1, padding: '32px', backgroundColor: '#f0f8ff', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom align="center" style={{ color: '#2e7d32' }}>
        Shop Management
      </Typography>

      <Box sx={{ textAlign: 'center', mt: 2, mb: 4 }}>
        <Button
          disabled={!isAdmin}
          variant="contained"
          sx={{ backgroundColor: '#2e7d32', color: '#fff', marginRight: '16px' }}
          onClick={() => setOpenStockDialog(true)}
        >
          Stock Transfer
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#2e7d32', color: '#fff' }}
          onClick={() => setOpenProductDialog(true)}
        >
          Approve Products
        </Button>
      </Box>

      {/* Stock Transfer Dialog */}
      <Dialog open={openStockDialog} onClose={() => setOpenStockDialog(false)}>
        <DialogTitle>Stock Transfer</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>From Store</InputLabel>
                <Select
                  value={stockDetails.fromStore}
                  onChange={(e) => setStockDetails((prev) => ({ ...prev, fromStore: e.target.value }))}
                  label="From Store"
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
                >
                  {shops.map((shop) => (
                    <MenuItem key={shop.id} value={shop.shopName.toLowerCase()}>
                      {shop.shopName.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal" variant="outlined" disabled={!stockDetails.fromStore}>
                <InputLabel>To Store</InputLabel>
                <Select
                  value={stockDetails.toStore}
                  onChange={(e) => setStockDetails((prev) => ({ ...prev, toStore: e.target.value }))}
                  label="To Store"
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
                >
                  {shops
                    .filter((shop) => shop.shopName !== stockDetails.fromStore)
                    .map((shop) => (
                      <MenuItem key={shop.id} value={shop.shopName.toLowerCase()}>
                        {shop.shopName.toUpperCase()}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Category</InputLabel>
                <Select
                  value={stockDetails.category}
                  onChange={handleCategoryChange}
                  label="Category"
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.name.toLowerCase()}>
                      {category.name.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal" variant="outlined" disabled={!stockDetails.category}>
                <InputLabel>Product</InputLabel>
                <Select
                  value={stockDetails.product}
                  onChange={handleProductChange}
                  label="Product"
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
                >
                  {filteredProducts.map((product) => (
                    <MenuItem key={product.id} value={product.name.toLowerCase()}>
                      {product.name.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Buying Price"
                variant="outlined"
                value={stockDetails.buyingPrice}
                
                sx={{ marginBottom: '16px', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Selling Price"
                variant="outlined"
                value={stockDetails.sellingPrice}
               
                sx={{ marginBottom: '16px', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Serial Numbers</InputLabel>
                <Select
                  multiple
                  value={selectedSerialNumbers}
                  onChange={handleSerialNumberChange}
                  renderValue={(selected) => selected.join(', ')}
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
                >
                  {serialNumbers.map((serial) => (
                    <MenuItem key={serial} value={serial}>
                      <Checkbox checked={selectedSerialNumbers.indexOf(serial) > -1} />
                      <ListItemText primary={serial} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenStockDialog(false)} sx={{ color: '#2e7d32' }}>
            Cancel
          </Button>
          <Button
  onClick={async () => {
    const product = products.find((p) => p.name === stockDetails.product);
    const productName = product ? product.name : '';

    if (stockDetails.fromStore && stockDetails.toStore && productName && selectedSerialNumbers.length > 0) {
      await transferProduct(stockDetails.fromStore, stockDetails.toStore, productName, selectedSerialNumbers);
    } else {
      toast("Please select all required fields and serial numbers.",productName);
    }
  }}
  variant="contained"
  sx={{ backgroundColor: '#2e7d32', color: '#fff' }}
>
  Transfer Stock
</Button>

        </DialogActions>
      </Dialog>

      {/* Approve Products Dialog */}
      <Dialog
      open={openProductDialog}
      onClose={() => setOpenProductDialog(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Pending Product Approvals</DialogTitle>
      <DialogContent>
        <MUIDataTable
          title={"Pending Products"}
          data={data}
          columns={columns}
          options={options}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenProductDialog(false)} sx={{ color: "#2e7d32" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
      <Dialog open={openRejectDialog} onClose={() => setOpenRejectDialog(false)}>
  <DialogTitle>Reject Product</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Rejection Reason"
      fullWidth
      variant="outlined"
      value={rejectReason}
      onChange={(e) => setRejectReason(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenRejectDialog(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={handleRejectProduct} color="secondary">
      Reject
    </Button>
  </DialogActions>
</Dialog>

    </Box>
    </ThemeProvider>
  );
}

export default ManageShopPage;
