import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ salesOverview }) => {
  const salesOverviewData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: salesOverview.map((overview, index) => ({
      label: overview.shopName,
      data: [
        overview.week1Sales,
        overview.week2Sales,
        overview.week3Sales,
        overview.week4Sales,
      ],
      backgroundColor: `rgba(${(index * 60) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 0.4)`,
      borderColor: `rgba(${(index * 60) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 1)`,
      borderWidth: 2,
      hoverBackgroundColor: `rgba(${(index * 60) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 0.6)`,
      hoverBorderColor: `rgba(${(index * 60) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 1)`,
    })),
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '4 Weeks Sales Overview by Shop',
        color: '#333',
        font: {
          size: 20,
          weight: 'bold',
        },
        padding: {
          top: 10,
          bottom: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y.toFixed(2)}`;
            }
            return label;
          }
        },
        backgroundColor: '#333',
        titleColor: '#fff',
        bodyColor: '#fff',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Weeks',
        },
        grid: {
          display: true, // Show gridlines for x-axis
          color: '#ddd', // Gridline color
          lineWidth: 1, // Gridline width
          borderDash: [5, 5], // Dashed gridlines (optional)
        },
        ticks: {
          color: '#000',
          font: {
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Sales (ksh)',
        },
        grid: {
          display: true, // Show gridlines for y-axis
          color: '#ddd', // Gridline color
          lineWidth: 1, // Gridline width
          borderDash: [5, 5], // Dashed gridlines (optional)
        },
        ticks: {
          callback: function(value) {
            return `${value}`;
          },
          color: '#000',
          font: {
            size: 14,
          },
        },
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad',
    },
  };

  return <Bar data={salesOverviewData} options={chartOptions} />;
};

export default BarChart;
