import React from 'react';
import Card from 'react-bootstrap/Card';
import '../components/shopCard.css'; // Import the CSS file

const ShopCard = ({ shop }) => {
  return (
    <Card className="shop-card">
      <Card.Body>
        {/* Shop name with a dynamic class based on the shop name */}
        <Card.Title className={`shop-card-title ${shop.name}`}>
          {shop.name}
        </Card.Title>
        {/* Revenue, Profit, Daily Sales with Arial font */}
        <Card.Text className="shop-card-text">Revenue: Ksh {shop.revenue}</Card.Text>
        <Card.Text className="shop-card-text">Profit: Ksh {shop.profit}</Card.Text>
        <Card.Text className="shop-card-text">Daily Sales: Ksh {shop.dailySales}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ShopCard;
