import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Link,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { Edit, Delete, Description } from "@mui/icons-material";
import { styled } from "@mui/system";
import { collection, doc, getDocs, updateDoc, deleteDoc, query, where } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path according to your file structure

const useStyles = styled((theme) => ({
  root: {
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  dialog: {
    padding: theme.spacing(2),
  },
}));

const ManageEmployees = () => {
  const classes = useStyles();
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [shops, setShops] = useState([]);
  const [editEmployee, setEditEmployee] = useState({
    id: "",
    name: "",
    role: "",
    email: "",
    status: "",
    dob: "",
    assignedShop: "",
  
  });
  const [fireReason, setFireReason] = useState("");

  useEffect(() => {
    const fetchShops = async () => {
      const q = query(collection(db, "shoplist"));
      const querySnapshot = await getDocs(q);
      const shopList = querySnapshot.docs.map((doc) => doc.data().shopName.toUpperCase());
      setShops(shopList);
    };

    fetchShops();
    const fetchEmployees = async () => {
      const employeesCollection = collection(db, "employees");
      const employeeSnapshot = await getDocs(employeesCollection);
      const employeeList = employeeSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeeList);
    };
    fetchEmployees();
  }, []);

  const fetchEmployeeByEmail = async (email) => {
    const q = query(collection(db, "employees"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id; // Return the first matching document ID
    }
    return null;
  };

  const handleEdit = async (employee) => {
    const id = await fetchEmployeeByEmail(employee.email);
    if (id) {
      setEditEmployee({ ...employee, id });
      setDialogType("edit");
      setOpenDialog(true);
    }
  };

  const handleFire = async (employee) => {
    const id = await fetchEmployeeByEmail(employee.email);
    if (id) {
      setSelectedEmployee({ ...employee, id });
      setDialogType("fire");
      setOpenDialog(true);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedEmployee(null);
    setEditEmployee({
      id: "",
      name: "",
      role: "",
      email: "",
      status: "",
      dob: "",
      assignedShop: "",
      passportPhoto: "",
      idDocument: "",
      goodConduct: "",
      kraDocument: "",
      academicDocuments: "",
    });
    setFireReason("");
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditEmployee({ ...editEmployee, [name]: value });
  };

  const handleEditSubmit = async () => {
    const employeeRef = doc(db, "employees", editEmployee.id);
    await updateDoc(employeeRef, editEmployee);
    setEmployees(
      employees.map((emp) => (emp.id === editEmployee.id ? editEmployee : emp))
    );
    handleDialogClose();
  };

  const handleFireSubmit = async () => {
    const employeeRef = doc(db, "employees", selectedEmployee.id);
    await updateDoc(employeeRef, { status: `Fired (${fireReason})` });
    setEmployees(
      employees.map((emp) =>
        emp.id === selectedEmployee.id
          ? { ...emp, status: `Fired (${fireReason})` }
          : emp
      )
    );
    handleDialogClose();
  };

  const handleDelete = async (email) => {
    const id = await fetchEmployeeByEmail(email);
    if (id) {
      await deleteDoc(doc(db, "employees", id));
      setEmployees(employees.filter((employee) => employee.id !== id));
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper className={classes.root} elevation={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Manage Employees
        </Typography>
        <TableContainer>
          <Table className={classes.table} aria-label="employee table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Shop</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Documents</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => (
                <TableRow key={employee.id}>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.email}</TableCell>
                  <TableCell>{employee.role}</TableCell>
                  <TableCell>{employee.assignedShop}</TableCell>
                  <TableCell>{employee.status}</TableCell>
                  <TableCell>
                    {employee.passportPhoto && (
                      <Tooltip title="Passport Photo" arrow>
                        <Link href={employee.passportPhoto} target="_blank">
                          <IconButton>
                            <Description />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {employee.idDocument && (
                      <Tooltip title="ID Document" arrow>
                        <Link href={employee.idDocument} target="_blank">
                          <IconButton>
                            <Description />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {employee.goodConduct && (
                      <Tooltip title="Good Conduct Certificate" arrow>
                        <Link href={employee.goodConduct} target="_blank">
                          <IconButton>
                            <Description />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {employee.kraDocument && (
                      <Tooltip title="KRA Document" arrow>
                        <Link href={employee.kraDocument} target="_blank">
                          <IconButton>
                            <Description />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {employee.academicDocuments && (
                      <Tooltip title="Academic Documents" arrow>
                        <Link href={employee.academicDocuments} target="_blank">
                          <IconButton>
                            <Description />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(employee)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleFire(employee)}
                    >
                      <Delete />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(employee.email)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>
            {dialogType === "edit" ? "Edit Employee" : "Fire Employee"}
          </DialogTitle>
          <DialogContent className={classes.dialog}>
            {dialogType === "edit" ? (
              <Box>
                <TextField
                  label="Name"
                  name="name"
                  value={editEmployee.name}
                  onChange={handleEditChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="ID"
                  name="id"
                  value={editEmployee.id}
                  onChange={handleEditChange}
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Date of Birth"
                  name="dob"
                  type="date"
                  value={editEmployee.dob}
                  onChange={handleEditChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={editEmployee.email}
                  onChange={handleEditChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Role"
                  name="role"
                  select
                  value={editEmployee.role}
                  onChange={handleEditChange}
                  fullWidth
                  margin="normal"
                >
                   <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="salesperson">Salesperson</MenuItem>
                  <MenuItem value="shopmanager">Shop Manager</MenuItem>
                </TextField>
                <TextField
                  label="Status"
                  name="status"
                  type="status"
                  value={editEmployee.status}
                  onChange={handleEditChange}
                  fullWidth
                  margin="normal"
                />
                <Grid item xs={6}>
              <TextField
                label="Assigned Shop"
                name="assignedShop"
                value={editEmployee.assignedShop}
                onChange={handleEditChange}
                required
                select
                fullWidth
                className={classes.input}
              >
                {shops.map((shop) => (
                  <MenuItem key={shop} value={shop.toLowerCase()}>
                    {shop}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
              </Box>
            ) : (
              <TextField
                label="Reason for Firing"
                value={fireReason}
                onChange={(e) => setFireReason(e.target.value)}
                fullWidth
                margin="normal"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            {dialogType === "edit" ? (
              <Button color="primary" onClick={handleEditSubmit}>
                Save
              </Button>
            ) : (
              <Button color="secondary" onClick={handleFireSubmit}>
                Fire
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default ManageEmployees;
