import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Container,
  Box,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import { styled } from "@mui/system";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase"; // Adjust the path according to your file structure
import { toast } from "react-toastify"; // Ensure you have react-toastify installed

const useStyles = styled((theme) => ({
  root: {
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    transition: "background-color 0.3s, border-color 0.3s",
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.primary.main,
    },
  },
  uploadIcon: {
    marginRight: theme.spacing(1),
  },
  fileInput: {
    display: 'none',
  },
  input: {
    '& .MuiInputBase-root': {
      borderColor: theme.palette.success.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.success.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.success.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.success.main,
      },
    },
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const DoughnutProgress = ({ progress, color, label }) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress variant="determinate" value={progress} size={80} thickness={5} style={{ color }} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
    </Box>
    <Box
      top="100%"
      left="50%"
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ transform: 'translate(-50%, 0)' }}
    >
      <Typography variant="caption" component="div" color="textSecondary">{label}</Typography>
    </Box>
  </Box>
);

const AddSalesperson = () => {
  const classes = useStyles();

  const [salesperson, setSalesperson] = useState({
    name: "",
    id: "",
    dob: "",
    email: "",
    role: "",
    status:"working",
    assignedShop: "",
    passportPhoto: null,
    idDocument: null,
    goodConduct: null,
    kraDocument: null,
    academicDocuments: null,
  });

  const [shops, setShops] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({
    passportPhoto: 0,
    idDocument: 0,
    goodConduct: 0,
    kraDocument: 0,
    academicDocuments: 0,
  });
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [currentUpload, setCurrentUpload] = useState("");

  useEffect(() => {
    const fetchShops = async () => {
      const q = query(collection(db, "shoplist"));
      const querySnapshot = await getDocs(q);
      const shopList = querySnapshot.docs.map((doc) => doc.data().shopName.toUpperCase());
      setShops(shopList);
    };

    fetchShops();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSalesperson({ ...salesperson, [name]: value.toLowerCase() });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setSalesperson({ ...salesperson, [name]: files[0] });
  };

  const validateForm = () => {
    const { name, id, dob, email, role, assignedShop, passportPhoto, idDocument, goodConduct, kraDocument, academicDocuments } = salesperson;
    if (!name || !id || !dob || !email || !role || !assignedShop || !passportPhoto || !idDocument || !goodConduct || !kraDocument || !academicDocuments) {
      toast.error("Please fill out all fields and upload all documents.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    const emailLower = salesperson.email.toLowerCase();
    const q = query(collection(db, "employees"), where("email", "==", emailLower));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      toast.error("Employee with this email already exists.");
      return;
    }
  
    // Check if the role is "shopmanager" and if the assigned shop already has a shop manager
    if (salesperson.role.toLowerCase() === "shopmanager") {
      const shopQuery = query(
        collection(db, "employees"),
        where("assignedShop", "==", salesperson.assignedShop.toLowerCase()),
        where("role", "==", "shopmanager")
      );
      const shopSnapshot = await getDocs(shopQuery);
  
      if (!shopSnapshot.empty) {
        toast.error("This shop already has an assigned shop manager.");
        return;
      }
    }
  
    const uploadFile = async (file, path, label) => {
      const fileRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(fileRef, file);
  
      setUploadDialogOpen(true);
      setCurrentUpload(label);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [label]: progress,
            }));
          },
          (error) => {
            setUploadDialogOpen(false);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    };
  
    try {
      const uploadPromises = [
        uploadFile(salesperson.passportPhoto, `employees/${emailLower}/passportPhoto`, "passportPhoto"),
        uploadFile(salesperson.idDocument, `employees/${emailLower}/idDocument`, "idDocument"),
        uploadFile(salesperson.goodConduct, `employees/${emailLower}/goodConduct`, "goodConduct"),
        uploadFile(salesperson.kraDocument, `employees/${emailLower}/kraDocument`, "kraDocument"),
        uploadFile(salesperson.academicDocuments, `employees/${emailLower}/academicDocuments`, "academicDocuments"),
      ];
  
      const [
        passportPhotoURL,
        idDocumentURL,
        goodConductURL,
        kraDocumentURL,
        academicDocumentsURL,
      ] = await Promise.all(uploadPromises);
  
      setUploadDialogOpen(false);
  
      const employeeData = {
        ...salesperson,
        email: emailLower,
        assignedShop: salesperson.assignedShop.toLowerCase(),
        passportPhoto: passportPhotoURL,
        idDocument: idDocumentURL,
        goodConduct: goodConductURL,
        kraDocument: kraDocumentURL,
        academicDocuments: academicDocumentsURL,
      };
  
      await addDoc(collection(db, "employees"), employeeData);
  
      toast.success("Employee added successfully.");
  
      // Clear form data
      setSalesperson({
        name: "",
        id: "",
        dob: "",
        email: "",
        role: "",
        assignedShop: "",
        passportPhoto: null,
        idDocument: null,
        goodConduct: null,
        kraDocument: null,
        academicDocuments: null,
      });
    } catch (error) {
      console.error("Error adding employee:", error);
      toast.error("Error adding employee. Please try again.");
    }
  };
  

  return (
    <Container maxWidth="lg">
      <Paper className={classes.root} elevation={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add Employee
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Name"
                name="name"
                value={salesperson.name}
                onChange={handleChange}
                required
                fullWidth
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="ID"
                name="id"
                value={salesperson.id}
                onChange={handleChange}
                required
                fullWidth
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Date of Birth"
                name="dob"
                type="date"
                value={salesperson.dob}
                onChange={handleChange}
                required
                fullWidth
                className={classes.input}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={salesperson.email}
                onChange={handleChange}
                required
                fullWidth
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Role"
                name="role"
                value={salesperson.role}
                onChange={handleChange}
                required
                select
                fullWidth
                className={classes.input}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="salesperson">Salesperson</MenuItem>
                <MenuItem value="shopmanager">Shop Manager</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Assigned Shop"
                name="assignedShop"
                value={salesperson.assignedShop}
                onChange={handleChange}
                required
                select
                fullWidth
                className={classes.input}
              >
                {shops.map((shop) => (
                  <MenuItem key={shop} value={shop.toLowerCase()}>
                    {shop}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Typography variant="subtitle1">Passport Photo</Typography>
              <label htmlFor="passportPhoto">
                <input
                  id="passportPhoto"
                  type="file"
                  name="passportPhoto"
                  accept="image/*"
                  onChange={handleFileChange}
                  className={classes.fileInput}
                />
                <Box className={classes.uploadButton}>
                  <Upload className={classes.uploadIcon} />
                  Upload Passport Photo
                </Box>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant="subtitle1">ID Document</Typography>
              <label htmlFor="idDocument">
                <input
                  id="idDocument"
                  type="file"
                  name="idDocument"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  className={classes.fileInput}
                />
                <Box className={classes.uploadButton}>
                  <Upload className={classes.uploadIcon} />
                  Upload ID Document
                </Box>
              </label>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Typography variant="subtitle1">Good Conduct</Typography>
              <label htmlFor="goodConduct">
                <input
                  id="goodConduct"
                  type="file"
                  name="goodConduct"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  className={classes.fileInput}
                />
                <Box className={classes.uploadButton}>
                  <Upload className={classes.uploadIcon} />
                  Upload Good Conduct
                </Box>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant="subtitle1">KRA Document</Typography>
              <label htmlFor="kraDocument">
                <input
                  id="kraDocument"
                  type="file"
                  name="kraDocument"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  className={classes.fileInput}
                />
                <Box className={classes.uploadButton}>
                  <Upload className={classes.uploadIcon} />
                  Upload KRA Document
                </Box>
              </label>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Typography variant="subtitle1">Academic Documents</Typography>
              <label htmlFor="academicDocuments">
                <input
                  id="academicDocuments"
                  type="file"
                  name="academicDocuments"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  className={classes.fileInput}
                />
                <Box className={classes.uploadButton}>
                  <Upload className={classes.uploadIcon} />
                  Upload Academic Documents
                </Box>
              </label>
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submitButton}
          >
            Add Person
          </Button>
        </form>
      </Paper>
      
      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)}>
        <DialogTitle>Uploading Files</DialogTitle>
        <DialogContent>
          {["passportPhoto", "idDocument", "goodConduct", "kraDocument", "academicDocuments"].map((doc) => (
            <Box key={doc} display="flex" alignItems="center" mb={2}>
              <DoughnutProgress
                progress={uploadProgress[doc]}
                color={
                  doc === "passportPhoto"
                    ? "#3f51b5"
                    : doc === "idDocument"
                    ? "#f50057"
                    : doc === "goodConduct"
                    ? "#ff9800"
                    : doc === "kraDocument"
                    ? "#4caf50"
                    : "#9c27b0"
                }
                label={
                  doc === "passportPhoto"
                    ? "Passport"
                    : doc === "idDocument"
                    ? "ID"
                    : doc === "goodConduct"
                    ? "Good Conduct"
                    : doc === "kraDocument"
                    ? "KRA"
                    : "Academic"
                }
              />
              <Typography variant="body2" style={{ marginLeft: 16 }}>
                {doc === currentUpload ? "Uploading..." : ""}
              </Typography>
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default AddSalesperson;
