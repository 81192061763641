import { collection, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase'; // Assume this is your Firebase setup file
import { toast } from 'react-toastify';

class DashboardDataService {
  
  async getShopList() {
    try {
      const shopListRef = collection(db, 'shoplist');
      const shopListSnapshot = await getDocs(shopListRef);
      return shopListSnapshot.docs.map(doc => doc.data().shopName);
    } catch (error) {
      console.error("Error fetching shop list:", error);
      throw error;
    }
  }

  async getSalesData(shopName, salesCollection) {
    const salesRef = collection(db, `sales/${shopName}/${salesCollection}`);
    return getDocs(salesRef);
  }
  calculateSales(snapshot) {
    if (!snapshot || !snapshot.docs || snapshot.docs.length === 0) {
      return { revenue: 0, profit: 0 }; // Return default values if snapshot or docs are empty
    }
  
    return snapshot.docs.reduce((acc, doc) => {
      const products = doc.data().products || []; // Ensure products is an array
      products.forEach(product => {
        const price = product.price || 0;
        const buyingPrice = product.buyingprice || 0;
        const quantity = product.quantity || 0;
        acc.revenue += price * quantity;
        acc.profit += (price - buyingPrice) * quantity;
      });
      return acc;
    }, { revenue: 0, profit: 0 });
  }
  
  async getRevenueAndProfit(shopName) {
    try {
      const [creditSalesSnapshot, mpesaSalesSnapshot] = await Promise.all([
        this.getSalesData(shopName, 'credit_sales'),
        this.getSalesData(shopName, 'mpesa_sales'),
      ]);
  
      // Filter sales where customerCategory is 'Walkin' or 'Onfone'
      const filterSalesByCategory = (snapshot) => {
        const filteredDocs = snapshot.docs.filter(doc =>
          doc.data().customerCategory === 'Walkin' || doc.data().customerCategory === 'Onfone'
        );
        return { docs: filteredDocs }; // Return the filtered docs wrapped in the same structure
      };
  
      // Apply filtering before calculating sales
      const filteredCreditSalesSnapshot = filterSalesByCategory(creditSalesSnapshot);
      const filteredMpesaSalesSnapshot = filterSalesByCategory(mpesaSalesSnapshot);
  
      // Calculate revenue and profit based on filtered sales
      const creditSales = this.calculateSales(filteredCreditSalesSnapshot);
      const mpesaSales = this.calculateSales(filteredMpesaSalesSnapshot);
  
      return {
        revenue: creditSales.revenue + mpesaSales.revenue,
        profit: creditSales.profit + mpesaSales.profit,
      };
    } catch (error) {
      console.error("Error fetching revenue and profit:", error);
      throw error;
    }
  }
  
  

  async getCategorySales(shopName, category, startOfDayTimestamp, endOfDayTimestamp) {
    try {
      const salesRef = collection(db, `sales/${shopName}/credit_sales`);
      const q = query(
        salesRef,
        where("customerCategory", "==", category),
        where("saleTimestamp", ">=", startOfDayTimestamp),
        where("saleTimestamp", "<=", endOfDayTimestamp)
      );

      const salesSnapshot = await getDocs(q);
      return salesSnapshot.docs.reduce((total, doc) => total + doc.data().total, 0);
    } catch (error) {
      console.error(`Error fetching category sales for ${category}:`, error);
      throw error;
    }
  }

  async populateSalesOverview() {
    try {
      const shopNames = await this.getShopList();
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const startOfMonth = Timestamp.fromDate(firstDayOfMonth);
      const endOfMonth = Timestamp.fromDate(new Date(lastDayOfMonth.setHours(23, 59, 59, 999)));
  
      const salesDataPromises = shopNames.map(async (shopName) => {
        const [lnmSales, watuSimuSales, onphoneSales] = await Promise.all([
          this.getCategorySales(shopName, 'LMM', startOfMonth, endOfMonth),
          this.getCategorySales(shopName, 'Watu Simu', startOfMonth, endOfMonth),
          this.getCategorySales(shopName, 'Onfone', startOfMonth, endOfMonth),
        ]);

        return { shopName, lnmSales, watuSimuSales, onphoneSales };
      });

      return await Promise.all(salesDataPromises);
    } catch (error) {
      console.error("Error populating sales overview:", error);
      throw error;
    }
  }

  async getSalesInRange(shopName, salesCollection, startTimestamp, endTimestamp) {
    const salesRef = collection(db, `sales/${shopName}/${salesCollection}`);
    const q = query(salesRef, where("saleTimestamp", ">=", startTimestamp), where("saleTimestamp", "<=", endTimestamp));
    const snapshot = await getDocs(q);
    return snapshot.docs.reduce((total, doc) => {
      const products = doc.data().products;
      return total + products.reduce((sum, product) => sum + product.price * product.quantity, 0);
    }, 0);
  }

  async getWeeklySales(shopName, startOfWeek, endOfWeek) {
    try {
      const [creditSales, mpesaSales] = await Promise.all([
        this.getSalesInRange(shopName, 'credit_sales', startOfWeek, endOfWeek),
        this.getSalesInRange(shopName, 'mpesa_sales', startOfWeek, endOfWeek),
      ]);
  
      // Assuming creditSales and mpesaSales are numbers
      return (creditSales || 0) + (mpesaSales || 0);
    } catch (error) {
      console.error("Error fetching weekly sales:", error);
      throw new Error("Unable to fetch weekly sales data.");
    }
  }
  
  getWeekRange(weeksAgo = 0) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
  
    const currentDayOfWeek = now.getDay();
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - currentDayOfWeek - (7 * weeksAgo));
  
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
  
    // Return as Date objects instead of Timestamp
    return {
      startOfWeek,
      endOfWeek
    };
  }
  
  async populateWeeklySales(shopName) {
    try {
      const weeklySalesData = {};
  
      for (let i = 0; i < 4; i++) {
        const { startOfWeek, endOfWeek } = this.getWeekRange(i);
        const sales = await this.getWeeklySales(shopName, startOfWeek, endOfWeek);
        weeklySalesData[`week${i + 1}Sales`] = sales;
        //toast.info(`${shopName} - Week ${i + 1}: ${sales}`);
      }
  
      return {
        shopName,
        week1Sales: weeklySalesData.week1Sales,
        week2Sales: weeklySalesData.week2Sales,
        week3Sales: weeklySalesData.week3Sales,
        week4Sales: weeklySalesData.week4Sales,
      };
    } catch (error) {
      toast.error("Error populating weekly sales:", error);
      throw new Error("Unable to populate weekly sales data.");
    }
  }
  
  
  

  async getDailySales(shopName, startOfDayTimestamp, endOfDayTimestamp) {
    try {
      const [creditSales, mpesaSales] = await Promise.all([
        this.getSalesInRange(shopName, 'credit_sales', startOfDayTimestamp, endOfDayTimestamp),
        this.getSalesInRange(shopName, 'mpesa_sales', startOfDayTimestamp, endOfDayTimestamp),
      ]);

      return creditSales + mpesaSales;
    } catch (error) {
      console.error("Error fetching daily sales:", error);
      throw error;
    }
  }

  async populateDashboard() {
    try {
      const shopNames = await this.getShopList();
      const today = new Date();
      const startOfDayTimestamp = Timestamp.fromDate(new Date(today.setHours(0, 0, 0, 0)));
      const endOfDayTimestamp = Timestamp.fromDate(new Date(today.setHours(23, 59, 59, 999)));

      const dashboardDataPromises = shopNames.map(async (shopName) => {
        const [revenueAndProfit, dailySales, weeklySales] = await Promise.all([
          this.getRevenueAndProfit(shopName),
          this.getDailySales(shopName, startOfDayTimestamp, endOfDayTimestamp),
          this.populateWeeklySales(shopName),
        ]);

        return {
          name: shopName,
          revenue: revenueAndProfit.revenue,
          profit: revenueAndProfit.profit,
          dailySales,
          weeklySales,
        };
      });

      return await Promise.all(dashboardDataPromises);
    } catch (error) {
      console.error("Error populating dashboard:", error);
      throw error;
    }
  }
}

export default new DashboardDataService();
