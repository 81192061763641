import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function UserManagement() {
  return (
    <Box component="form" noValidate autoComplete="off">
      <TextField
        fullWidth
        label="Admin Email"
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="New Password"
        variant="outlined"
        type="password"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Confirm Password"
        variant="outlined"
        type="password"
        margin="normal"
      />
      <Button variant="contained" color="primary" sx={{ mt: 2 }}>
        Save Changes
      </Button>
    </Box>
  );
}

export default UserManagement;
